'use strict';

var cov_ofivefy5v = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/logical_plan/logical_planCtrl.js',
      hash = 'de51afd9dc08ffc36ae3a0dbbaf20b994da6d91f',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/logical_plan/logical_planCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 455,
          column: 3
        }
      },
      '1': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 5,
          column: 37
        }
      },
      '2': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 8,
          column: 3
        }
      },
      '3': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 7,
          column: 11
        }
      },
      '4': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 29
        }
      },
      '5': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 10,
          column: 33
        }
      },
      '6': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 34
        }
      },
      '7': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 14,
          column: 4
        }
      },
      '8': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 13,
          column: 36
        }
      },
      '9': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 35
        }
      },
      '10': {
        start: {
          line: 16,
          column: 2
        },
        end: {
          line: 53,
          column: 5
        }
      },
      '11': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 52,
          column: 7
        }
      },
      '12': {
        start: {
          line: 18,
          column: 20
        },
        end: {
          line: 18,
          column: 21
        }
      },
      '13': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 39,
          column: 7
        }
      },
      '14': {
        start: {
          line: 21,
          column: 23
        },
        end: {
          line: 21,
          column: 105
        }
      },
      '15': {
        start: {
          line: 21,
          column: 71
        },
        end: {
          line: 21,
          column: 103
        }
      },
      '16': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 37,
          column: 9
        }
      },
      '17': {
        start: {
          line: 24,
          column: 10
        },
        end: {
          line: 36,
          column: 13
        }
      },
      '18': {
        start: {
          line: 38,
          column: 8
        },
        end: {
          line: 38,
          column: 18
        }
      },
      '19': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 49,
          column: 9
        }
      },
      '20': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 44,
          column: 9
        }
      },
      '21': {
        start: {
          line: 43,
          column: 10
        },
        end: {
          line: 43,
          column: 19
        }
      },
      '22': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 47,
          column: 9
        }
      },
      '23': {
        start: {
          line: 46,
          column: 10
        },
        end: {
          line: 46,
          column: 19
        }
      },
      '24': {
        start: {
          line: 48,
          column: 8
        },
        end: {
          line: 48,
          column: 18
        }
      },
      '25': {
        start: {
          line: 51,
          column: 6
        },
        end: {
          line: 51,
          column: 41
        }
      },
      '26': {
        start: {
          line: 54,
          column: 2
        },
        end: {
          line: 54,
          column: 42
        }
      },
      '27': {
        start: {
          line: 56,
          column: 2
        },
        end: {
          line: 56,
          column: 80
        }
      },
      '28': {
        start: {
          line: 58,
          column: 2
        },
        end: {
          line: 60,
          column: 5
        }
      },
      '29': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 59,
          column: 128
        }
      },
      '30': {
        start: {
          line: 62,
          column: 2
        },
        end: {
          line: 62,
          column: 26
        }
      },
      '31': {
        start: {
          line: 63,
          column: 17
        },
        end: {
          line: 63,
          column: 32
        }
      },
      '32': {
        start: {
          line: 64,
          column: 19
        },
        end: {
          line: 64,
          column: 35
        }
      },
      '33': {
        start: {
          line: 65,
          column: 15
        },
        end: {
          line: 65,
          column: 26
        }
      },
      '34': {
        start: {
          line: 66,
          column: 18
        },
        end: {
          line: 66,
          column: 30
        }
      },
      '35': {
        start: {
          line: 67,
          column: 2
        },
        end: {
          line: 67,
          column: 30
        }
      },
      '36': {
        start: {
          line: 69,
          column: 2
        },
        end: {
          line: 69,
          column: 35
        }
      },
      '37': {
        start: {
          line: 71,
          column: 2
        },
        end: {
          line: 71,
          column: 31
        }
      },
      '38': {
        start: {
          line: 72,
          column: 2
        },
        end: {
          line: 72,
          column: 26
        }
      },
      '39': {
        start: {
          line: 73,
          column: 2
        },
        end: {
          line: 73,
          column: 27
        }
      },
      '40': {
        start: {
          line: 74,
          column: 2
        },
        end: {
          line: 79,
          column: 4
        }
      },
      '41': {
        start: {
          line: 75,
          column: 4
        },
        end: {
          line: 77,
          column: 5
        }
      },
      '42': {
        start: {
          line: 76,
          column: 6
        },
        end: {
          line: 76,
          column: 34
        }
      },
      '43': {
        start: {
          line: 78,
          column: 4
        },
        end: {
          line: 78,
          column: 28
        }
      },
      '44': {
        start: {
          line: 80,
          column: 2
        },
        end: {
          line: 83,
          column: 4
        }
      },
      '45': {
        start: {
          line: 81,
          column: 4
        },
        end: {
          line: 81,
          column: 37
        }
      },
      '46': {
        start: {
          line: 82,
          column: 4
        },
        end: {
          line: 82,
          column: 36
        }
      },
      '47': {
        start: {
          line: 84,
          column: 2
        },
        end: {
          line: 94,
          column: 4
        }
      },
      '48': {
        start: {
          line: 85,
          column: 4
        },
        end: {
          line: 85,
          column: 26
        }
      },
      '49': {
        start: {
          line: 86,
          column: 4
        },
        end: {
          line: 86,
          column: 24
        }
      },
      '50': {
        start: {
          line: 87,
          column: 4
        },
        end: {
          line: 87,
          column: 24
        }
      },
      '51': {
        start: {
          line: 88,
          column: 4
        },
        end: {
          line: 92,
          column: 7
        }
      },
      '52': {
        start: {
          line: 89,
          column: 6
        },
        end: {
          line: 89,
          column: 30
        }
      },
      '53': {
        start: {
          line: 90,
          column: 6
        },
        end: {
          line: 90,
          column: 107
        }
      },
      '54': {
        start: {
          line: 90,
          column: 80
        },
        end: {
          line: 90,
          column: 102
        }
      },
      '55': {
        start: {
          line: 96,
          column: 2
        },
        end: {
          line: 104,
          column: 4
        }
      },
      '56': {
        start: {
          line: 97,
          column: 4
        },
        end: {
          line: 97,
          column: 24
        }
      },
      '57': {
        start: {
          line: 98,
          column: 4
        },
        end: {
          line: 98,
          column: 24
        }
      },
      '58': {
        start: {
          line: 99,
          column: 4
        },
        end: {
          line: 102,
          column: 7
        }
      },
      '59': {
        start: {
          line: 100,
          column: 6
        },
        end: {
          line: 100,
          column: 28
        }
      },
      '60': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 101,
          column: 59
        }
      },
      '61': {
        start: {
          line: 106,
          column: 2
        },
        end: {
          line: 110,
          column: 4
        }
      },
      '62': {
        start: {
          line: 112,
          column: 2
        },
        end: {
          line: 112,
          column: 42
        }
      },
      '63': {
        start: {
          line: 114,
          column: 2
        },
        end: {
          line: 118,
          column: 5
        }
      },
      '64': {
        start: {
          line: 116,
          column: 4
        },
        end: {
          line: 116,
          column: 58
        }
      },
      '65': {
        start: {
          line: 117,
          column: 4
        },
        end: {
          line: 117,
          column: 63
        }
      },
      '66': {
        start: {
          line: 121,
          column: 2
        },
        end: {
          line: 121,
          column: 29
        }
      },
      '67': {
        start: {
          line: 122,
          column: 2
        },
        end: {
          line: 122,
          column: 24
        }
      },
      '68': {
        start: {
          line: 123,
          column: 2
        },
        end: {
          line: 127,
          column: 4
        }
      },
      '69': {
        start: {
          line: 124,
          column: 4
        },
        end: {
          line: 126,
          column: 7
        }
      },
      '70': {
        start: {
          line: 125,
          column: 6
        },
        end: {
          line: 125,
          column: 35
        }
      },
      '71': {
        start: {
          line: 128,
          column: 2
        },
        end: {
          line: 132,
          column: 4
        }
      },
      '72': {
        start: {
          line: 129,
          column: 4
        },
        end: {
          line: 129,
          column: 96
        }
      },
      '73': {
        start: {
          line: 130,
          column: 4
        },
        end: {
          line: 130,
          column: 36
        }
      },
      '74': {
        start: {
          line: 131,
          column: 4
        },
        end: {
          line: 131,
          column: 29
        }
      },
      '75': {
        start: {
          line: 133,
          column: 2
        },
        end: {
          line: 137,
          column: 4
        }
      },
      '76': {
        start: {
          line: 134,
          column: 4
        },
        end: {
          line: 134,
          column: 28
        }
      },
      '77': {
        start: {
          line: 135,
          column: 4
        },
        end: {
          line: 135,
          column: 30
        }
      },
      '78': {
        start: {
          line: 136,
          column: 4
        },
        end: {
          line: 136,
          column: 47
        }
      },
      '79': {
        start: {
          line: 138,
          column: 2
        },
        end: {
          line: 149,
          column: 4
        }
      },
      '80': {
        start: {
          line: 139,
          column: 4
        },
        end: {
          line: 148,
          column: 7
        }
      },
      '81': {
        start: {
          line: 150,
          column: 2
        },
        end: {
          line: 153,
          column: 4
        }
      },
      '82': {
        start: {
          line: 151,
          column: 4
        },
        end: {
          line: 151,
          column: 31
        }
      },
      '83': {
        start: {
          line: 152,
          column: 4
        },
        end: {
          line: 152,
          column: 33
        }
      },
      '84': {
        start: {
          line: 154,
          column: 2
        },
        end: {
          line: 183,
          column: 4
        }
      },
      '85': {
        start: {
          line: 155,
          column: 4
        },
        end: {
          line: 182,
          column: 7
        }
      },
      '86': {
        start: {
          line: 167,
          column: 6
        },
        end: {
          line: 169,
          column: 7
        }
      },
      '87': {
        start: {
          line: 168,
          column: 8
        },
        end: {
          line: 168,
          column: 15
        }
      },
      '88': {
        start: {
          line: 170,
          column: 6
        },
        end: {
          line: 170,
          column: 37
        }
      },
      '89': {
        start: {
          line: 171,
          column: 6
        },
        end: {
          line: 181,
          column: 9
        }
      },
      '90': {
        start: {
          line: 172,
          column: 8
        },
        end: {
          line: 172,
          column: 40
        }
      },
      '91': {
        start: {
          line: 173,
          column: 8
        },
        end: {
          line: 179,
          column: 9
        }
      },
      '92': {
        start: {
          line: 174,
          column: 10
        },
        end: {
          line: 177,
          column: 13
        }
      },
      '93': {
        start: {
          line: 178,
          column: 10
        },
        end: {
          line: 178,
          column: 17
        }
      },
      '94': {
        start: {
          line: 180,
          column: 8
        },
        end: {
          line: 180,
          column: 42
        }
      },
      '95': {
        start: {
          line: 184,
          column: 2
        },
        end: {
          line: 216,
          column: 4
        }
      },
      '96': {
        start: {
          line: 185,
          column: 18
        },
        end: {
          line: 185,
          column: 36
        }
      },
      '97': {
        start: {
          line: 186,
          column: 4
        },
        end: {
          line: 186,
          column: 35
        }
      },
      '98': {
        start: {
          line: 187,
          column: 4
        },
        end: {
          line: 187,
          column: 27
        }
      },
      '99': {
        start: {
          line: 188,
          column: 4
        },
        end: {
          line: 188,
          column: 30
        }
      },
      '100': {
        start: {
          line: 189,
          column: 4
        },
        end: {
          line: 189,
          column: 82
        }
      },
      '101': {
        start: {
          line: 190,
          column: 4
        },
        end: {
          line: 190,
          column: 29
        }
      },
      '102': {
        start: {
          line: 191,
          column: 4
        },
        end: {
          line: 191,
          column: 58
        }
      },
      '103': {
        start: {
          line: 192,
          column: 4
        },
        end: {
          line: 195,
          column: 6
        }
      },
      '104': {
        start: {
          line: 196,
          column: 4
        },
        end: {
          line: 215,
          column: 7
        }
      },
      '105': {
        start: {
          line: 197,
          column: 6
        },
        end: {
          line: 197,
          column: 38
        }
      },
      '106': {
        start: {
          line: 198,
          column: 6
        },
        end: {
          line: 204,
          column: 7
        }
      },
      '107': {
        start: {
          line: 199,
          column: 8
        },
        end: {
          line: 202,
          column: 10
        }
      },
      '108': {
        start: {
          line: 203,
          column: 8
        },
        end: {
          line: 203,
          column: 15
        }
      },
      '109': {
        start: {
          line: 205,
          column: 6
        },
        end: {
          line: 211,
          column: 7
        }
      },
      '110': {
        start: {
          line: 206,
          column: 8
        },
        end: {
          line: 209,
          column: 10
        }
      },
      '111': {
        start: {
          line: 210,
          column: 8
        },
        end: {
          line: 210,
          column: 15
        }
      },
      '112': {
        start: {
          line: 212,
          column: 6
        },
        end: {
          line: 212,
          column: 31
        }
      },
      '113': {
        start: {
          line: 213,
          column: 6
        },
        end: {
          line: 213,
          column: 40
        }
      },
      '114': {
        start: {
          line: 214,
          column: 6
        },
        end: {
          line: 214,
          column: 28
        }
      },
      '115': {
        start: {
          line: 217,
          column: 2
        },
        end: {
          line: 219,
          column: 4
        }
      },
      '116': {
        start: {
          line: 218,
          column: 4
        },
        end: {
          line: 218,
          column: 47
        }
      },
      '117': {
        start: {
          line: 220,
          column: 2
        },
        end: {
          line: 230,
          column: 4
        }
      },
      '118': {
        start: {
          line: 221,
          column: 4
        },
        end: {
          line: 221,
          column: 29
        }
      },
      '119': {
        start: {
          line: 222,
          column: 4
        },
        end: {
          line: 229,
          column: 7
        }
      },
      '120': {
        start: {
          line: 231,
          column: 2
        },
        end: {
          line: 245,
          column: 4
        }
      },
      '121': {
        start: {
          line: 232,
          column: 4
        },
        end: {
          line: 232,
          column: 34
        }
      },
      '122': {
        start: {
          line: 233,
          column: 4
        },
        end: {
          line: 244,
          column: 7
        }
      },
      '123': {
        start: {
          line: 234,
          column: 6
        },
        end: {
          line: 243,
          column: 7
        }
      },
      '124': {
        start: {
          line: 235,
          column: 8
        },
        end: {
          line: 235,
          column: 35
        }
      },
      '125': {
        start: {
          line: 236,
          column: 8
        },
        end: {
          line: 236,
          column: 45
        }
      },
      '126': {
        start: {
          line: 237,
          column: 8
        },
        end: {
          line: 237,
          column: 42
        }
      },
      '127': {
        start: {
          line: 238,
          column: 8
        },
        end: {
          line: 238,
          column: 48
        }
      },
      '128': {
        start: {
          line: 239,
          column: 8
        },
        end: {
          line: 239,
          column: 37
        }
      },
      '129': {
        start: {
          line: 240,
          column: 8
        },
        end: {
          line: 240,
          column: 38
        }
      },
      '130': {
        start: {
          line: 242,
          column: 8
        },
        end: {
          line: 242,
          column: 35
        }
      },
      '131': {
        start: {
          line: 247,
          column: 19
        },
        end: {
          line: 252,
          column: 4
        }
      },
      '132': {
        start: {
          line: 254,
          column: 2
        },
        end: {
          line: 258,
          column: 4
        }
      },
      '133': {
        start: {
          line: 255,
          column: 4
        },
        end: {
          line: 255,
          column: 32
        }
      },
      '134': {
        start: {
          line: 256,
          column: 4
        },
        end: {
          line: 256,
          column: 30
        }
      },
      '135': {
        start: {
          line: 257,
          column: 4
        },
        end: {
          line: 257,
          column: 22
        }
      },
      '136': {
        start: {
          line: 259,
          column: 2
        },
        end: {
          line: 278,
          column: 4
        }
      },
      '137': {
        start: {
          line: 260,
          column: 4
        },
        end: {
          line: 274,
          column: 5
        }
      },
      '138': {
        start: {
          line: 261,
          column: 23
        },
        end: {
          line: 261,
          column: 49
        }
      },
      '139': {
        start: {
          line: 262,
          column: 6
        },
        end: {
          line: 264,
          column: 7
        }
      },
      '140': {
        start: {
          line: 263,
          column: 8
        },
        end: {
          line: 263,
          column: 49
        }
      },
      '141': {
        start: {
          line: 265,
          column: 6
        },
        end: {
          line: 267,
          column: 7
        }
      },
      '142': {
        start: {
          line: 266,
          column: 8
        },
        end: {
          line: 266,
          column: 49
        }
      },
      '143': {
        start: {
          line: 268,
          column: 6
        },
        end: {
          line: 271,
          column: 9
        }
      },
      '144': {
        start: {
          line: 272,
          column: 6
        },
        end: {
          line: 272,
          column: 35
        }
      },
      '145': {
        start: {
          line: 273,
          column: 6
        },
        end: {
          line: 273,
          column: 13
        }
      },
      '146': {
        start: {
          line: 275,
          column: 4
        },
        end: {
          line: 277,
          column: 7
        }
      },
      '147': {
        start: {
          line: 276,
          column: 6
        },
        end: {
          line: 276,
          column: 29
        }
      },
      '148': {
        start: {
          line: 279,
          column: 2
        },
        end: {
          line: 280,
          column: 4
        }
      },
      '149': {
        start: {
          line: 282,
          column: 2
        },
        end: {
          line: 282,
          column: 23
        }
      },
      '150': {
        start: {
          line: 283,
          column: 2
        },
        end: {
          line: 364,
          column: 4
        }
      },
      '151': {
        start: {
          line: 284,
          column: 13
        },
        end: {
          line: 284,
          column: 15
        }
      },
      '152': {
        start: {
          line: 285,
          column: 4
        },
        end: {
          line: 285,
          column: 26
        }
      },
      '153': {
        start: {
          line: 286,
          column: 4
        },
        end: {
          line: 286,
          column: 35
        }
      },
      '154': {
        start: {
          line: 287,
          column: 4
        },
        end: {
          line: 350,
          column: 5
        }
      },
      '155': {
        start: {
          line: 288,
          column: 6
        },
        end: {
          line: 303,
          column: 8
        }
      },
      '156': {
        start: {
          line: 304,
          column: 11
        },
        end: {
          line: 350,
          column: 5
        }
      },
      '157': {
        start: {
          line: 305,
          column: 6
        },
        end: {
          line: 319,
          column: 8
        }
      },
      '158': {
        start: {
          line: 320,
          column: 6
        },
        end: {
          line: 327,
          column: 7
        }
      },
      '159': {
        start: {
          line: 321,
          column: 8
        },
        end: {
          line: 326,
          column: 9
        }
      },
      '160': {
        start: {
          line: 322,
          column: 10
        },
        end: {
          line: 322,
          column: 65
        }
      },
      '161': {
        start: {
          line: 323,
          column: 10
        },
        end: {
          line: 323,
          column: 56
        }
      },
      '162': {
        start: {
          line: 324,
          column: 10
        },
        end: {
          line: 324,
          column: 69
        }
      },
      '163': {
        start: {
          line: 325,
          column: 10
        },
        end: {
          line: 325,
          column: 16
        }
      },
      '164': {
        start: {
          line: 329,
          column: 6
        },
        end: {
          line: 342,
          column: 8
        }
      },
      '165': {
        start: {
          line: 343,
          column: 6
        },
        end: {
          line: 349,
          column: 7
        }
      },
      '166': {
        start: {
          line: 344,
          column: 8
        },
        end: {
          line: 348,
          column: 9
        }
      },
      '167': {
        start: {
          line: 345,
          column: 10
        },
        end: {
          line: 345,
          column: 52
        }
      },
      '168': {
        start: {
          line: 346,
          column: 10
        },
        end: {
          line: 346,
          column: 69
        }
      },
      '169': {
        start: {
          line: 347,
          column: 10
        },
        end: {
          line: 347,
          column: 16
        }
      },
      '170': {
        start: {
          line: 351,
          column: 4
        },
        end: {
          line: 353,
          column: 6
        }
      },
      '171': {
        start: {
          line: 354,
          column: 4
        },
        end: {
          line: 354,
          column: 25
        }
      },
      '172': {
        start: {
          line: 356,
          column: 4
        },
        end: {
          line: 363,
          column: 7
        }
      },
      '173': {
        start: {
          line: 368,
          column: 4
        },
        end: {
          line: 370,
          column: 6
        }
      },
      '174': {
        start: {
          line: 369,
          column: 6
        },
        end: {
          line: 369,
          column: 42
        }
      },
      '175': {
        start: {
          line: 373,
          column: 2
        },
        end: {
          line: 382,
          column: 4
        }
      },
      '176': {
        start: {
          line: 374,
          column: 4
        },
        end: {
          line: 381,
          column: 7
        }
      },
      '177': {
        start: {
          line: 384,
          column: 2
        },
        end: {
          line: 439,
          column: 4
        }
      },
      '178': {
        start: {
          line: 385,
          column: 22
        },
        end: {
          line: 385,
          column: 31
        }
      },
      '179': {
        start: {
          line: 386,
          column: 4
        },
        end: {
          line: 438,
          column: 7
        }
      },
      '180': {
        start: {
          line: 398,
          column: 6
        },
        end: {
          line: 400,
          column: 7
        }
      },
      '181': {
        start: {
          line: 399,
          column: 8
        },
        end: {
          line: 399,
          column: 15
        }
      },
      '182': {
        start: {
          line: 401,
          column: 6
        },
        end: {
          line: 401,
          column: 26
        }
      },
      '183': {
        start: {
          line: 402,
          column: 27
        },
        end: {
          line: 408,
          column: 7
        }
      },
      '184': {
        start: {
          line: 410,
          column: 6
        },
        end: {
          line: 437,
          column: 9
        }
      },
      '185': {
        start: {
          line: 411,
          column: 8
        },
        end: {
          line: 411,
          column: 30
        }
      },
      '186': {
        start: {
          line: 412,
          column: 8
        },
        end: {
          line: 412,
          column: 34
        }
      },
      '187': {
        start: {
          line: 413,
          column: 8
        },
        end: {
          line: 434,
          column: 9
        }
      },
      '188': {
        start: {
          line: 414,
          column: 10
        },
        end: {
          line: 414,
          column: 35
        }
      },
      '189': {
        start: {
          line: 416,
          column: 20
        },
        end: {
          line: 416,
          column: 67
        }
      },
      '190': {
        start: {
          line: 418,
          column: 10
        },
        end: {
          line: 426,
          column: 11
        }
      },
      '191': {
        start: {
          line: 420,
          column: 14
        },
        end: {
          line: 420,
          column: 77
        }
      },
      '192': {
        start: {
          line: 421,
          column: 14
        },
        end: {
          line: 421,
          column: 20
        }
      },
      '193': {
        start: {
          line: 424,
          column: 14
        },
        end: {
          line: 424,
          column: 78
        }
      },
      '194': {
        start: {
          line: 425,
          column: 14
        },
        end: {
          line: 425,
          column: 20
        }
      },
      '195': {
        start: {
          line: 428,
          column: 10
        },
        end: {
          line: 431,
          column: 13
        }
      },
      '196': {
        start: {
          line: 433,
          column: 10
        },
        end: {
          line: 433,
          column: 17
        }
      },
      '197': {
        start: {
          line: 435,
          column: 8
        },
        end: {
          line: 435,
          column: 30
        }
      },
      '198': {
        start: {
          line: 436,
          column: 8
        },
        end: {
          line: 436,
          column: 43
        }
      },
      '199': {
        start: {
          line: 440,
          column: 2
        },
        end: {
          line: 443,
          column: 4
        }
      },
      '200': {
        start: {
          line: 441,
          column: 4
        },
        end: {
          line: 441,
          column: 25
        }
      },
      '201': {
        start: {
          line: 442,
          column: 4
        },
        end: {
          line: 442,
          column: 36
        }
      },
      '202': {
        start: {
          line: 445,
          column: 2
        },
        end: {
          line: 451,
          column: 5
        }
      },
      '203': {
        start: {
          line: 446,
          column: 4
        },
        end: {
          line: 450,
          column: 7
        }
      },
      '204': {
        start: {
          line: 453,
          column: 2
        },
        end: {
          line: 453,
          column: 23
        }
      },
      '205': {
        start: {
          line: 454,
          column: 2
        },
        end: {
          line: 454,
          column: 24
        }
      },
      '206': {
        start: {
          line: 458,
          column: 2
        },
        end: {
          line: 461,
          column: 4
        }
      },
      '207': {
        start: {
          line: 459,
          column: 4
        },
        end: {
          line: 459,
          column: 24
        }
      },
      '208': {
        start: {
          line: 460,
          column: 4
        },
        end: {
          line: 460,
          column: 40
        }
      },
      '209': {
        start: {
          line: 462,
          column: 2
        },
        end: {
          line: 464,
          column: 4
        }
      },
      '210': {
        start: {
          line: 463,
          column: 4
        },
        end: {
          line: 463,
          column: 75
        }
      },
      '211': {
        start: {
          line: 465,
          column: 2
        },
        end: {
          line: 474,
          column: 4
        }
      },
      '212': {
        start: {
          line: 466,
          column: 17
        },
        end: {
          line: 466,
          column: 32
        }
      },
      '213': {
        start: {
          line: 467,
          column: 4
        },
        end: {
          line: 469,
          column: 5
        }
      },
      '214': {
        start: {
          line: 468,
          column: 6
        },
        end: {
          line: 468,
          column: 18
        }
      },
      '215': {
        start: {
          line: 470,
          column: 4
        },
        end: {
          line: 472,
          column: 5
        }
      },
      '216': {
        start: {
          line: 471,
          column: 6
        },
        end: {
          line: 471,
          column: 18
        }
      },
      '217': {
        start: {
          line: 473,
          column: 4
        },
        end: {
          line: 473,
          column: 17
        }
      },
      '218': {
        start: {
          line: 475,
          column: 2
        },
        end: {
          line: 543,
          column: 4
        }
      },
      '219': {
        start: {
          line: 476,
          column: 4
        },
        end: {
          line: 476,
          column: 35
        }
      },
      '220': {
        start: {
          line: 477,
          column: 14
        },
        end: {
          line: 490,
          column: 5
        }
      },
      '221': {
        start: {
          line: 491,
          column: 4
        },
        end: {
          line: 491,
          column: 27
        }
      },
      '222': {
        start: {
          line: 493,
          column: 4
        },
        end: {
          line: 499,
          column: 5
        }
      },
      '223': {
        start: {
          line: 494,
          column: 6
        },
        end: {
          line: 497,
          column: 7
        }
      },
      '224': {
        start: {
          line: 495,
          column: 8
        },
        end: {
          line: 495,
          column: 30
        }
      },
      '225': {
        start: {
          line: 496,
          column: 8
        },
        end: {
          line: 496,
          column: 15
        }
      },
      '226': {
        start: {
          line: 498,
          column: 6
        },
        end: {
          line: 498,
          column: 49
        }
      },
      '227': {
        start: {
          line: 501,
          column: 4
        },
        end: {
          line: 506,
          column: 5
        }
      },
      '228': {
        start: {
          line: 502,
          column: 6
        },
        end: {
          line: 502,
          column: 35
        }
      },
      '229': {
        start: {
          line: 503,
          column: 6
        },
        end: {
          line: 503,
          column: 36
        }
      },
      '230': {
        start: {
          line: 504,
          column: 6
        },
        end: {
          line: 504,
          column: 13
        }
      },
      '231': {
        start: {
          line: 508,
          column: 18
        },
        end: {
          line: 508,
          column: 22
        }
      },
      '232': {
        start: {
          line: 509,
          column: 4
        },
        end: {
          line: 542,
          column: 7
        }
      },
      '233': {
        start: {
          line: 513,
          column: 6
        },
        end: {
          line: 515,
          column: 7
        }
      },
      '234': {
        start: {
          line: 514,
          column: 8
        },
        end: {
          line: 514,
          column: 15
        }
      },
      '235': {
        start: {
          line: 516,
          column: 6
        },
        end: {
          line: 538,
          column: 7
        }
      },
      '236': {
        start: {
          line: 517,
          column: 23
        },
        end: {
          line: 517,
          column: 94
        }
      },
      '237': {
        start: {
          line: 518,
          column: 8
        },
        end: {
          line: 532,
          column: 9
        }
      },
      '238': {
        start: {
          line: 519,
          column: 10
        },
        end: {
          line: 519,
          column: 32
        }
      },
      '239': {
        start: {
          line: 520,
          column: 10
        },
        end: {
          line: 520,
          column: 31
        }
      },
      '240': {
        start: {
          line: 521,
          column: 10
        },
        end: {
          line: 521,
          column: 35
        }
      },
      '241': {
        start: {
          line: 522,
          column: 10
        },
        end: {
          line: 522,
          column: 35
        }
      },
      '242': {
        start: {
          line: 523,
          column: 10
        },
        end: {
          line: 523,
          column: 94
        }
      },
      '243': {
        start: {
          line: 524,
          column: 15
        },
        end: {
          line: 532,
          column: 9
        }
      },
      '244': {
        start: {
          line: 525,
          column: 10
        },
        end: {
          line: 525,
          column: 32
        }
      },
      '245': {
        start: {
          line: 526,
          column: 10
        },
        end: {
          line: 526,
          column: 31
        }
      },
      '246': {
        start: {
          line: 527,
          column: 10
        },
        end: {
          line: 527,
          column: 35
        }
      },
      '247': {
        start: {
          line: 528,
          column: 10
        },
        end: {
          line: 528,
          column: 35
        }
      },
      '248': {
        start: {
          line: 529,
          column: 10
        },
        end: {
          line: 529,
          column: 69
        }
      },
      '249': {
        start: {
          line: 531,
          column: 10
        },
        end: {
          line: 531,
          column: 44
        }
      },
      '250': {
        start: {
          line: 534,
          column: 8
        },
        end: {
          line: 534,
          column: 29
        }
      },
      '251': {
        start: {
          line: 535,
          column: 8
        },
        end: {
          line: 535,
          column: 33
        }
      },
      '252': {
        start: {
          line: 536,
          column: 8
        },
        end: {
          line: 536,
          column: 33
        }
      },
      '253': {
        start: {
          line: 537,
          column: 8
        },
        end: {
          line: 537,
          column: 92
        }
      },
      '254': {
        start: {
          line: 539,
          column: 6
        },
        end: {
          line: 539,
          column: 28
        }
      },
      '255': {
        start: {
          line: 540,
          column: 6
        },
        end: {
          line: 540,
          column: 24
        }
      },
      '256': {
        start: {
          line: 541,
          column: 6
        },
        end: {
          line: 541,
          column: 26
        }
      },
      '257': {
        start: {
          line: 546,
          column: 2
        },
        end: {
          line: 550,
          column: 4
        }
      },
      '258': {
        start: {
          line: 548,
          column: 6
        },
        end: {
          line: 548,
          column: 42
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 53
          },
          end: {
            line: 4,
            column: 54
          }
        },
        loc: {
          start: {
            line: 4,
            column: 218
          },
          end: {
            line: 455,
            column: 1
          }
        },
        line: 4
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 12,
            column: 30
          },
          end: {
            line: 12,
            column: 31
          }
        },
        loc: {
          start: {
            line: 12,
            column: 47
          },
          end: {
            line: 14,
            column: 3
          }
        },
        line: 12
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 16,
            column: 39
          },
          end: {
            line: 16,
            column: 40
          }
        },
        loc: {
          start: {
            line: 16,
            column: 45
          },
          end: {
            line: 53,
            column: 3
          }
        },
        line: 16
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 17,
            column: 67
          },
          end: {
            line: 17,
            column: 68
          }
        },
        loc: {
          start: {
            line: 17,
            column: 75
          },
          end: {
            line: 52,
            column: 5
          }
        },
        line: 17
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 21,
            column: 61
          },
          end: {
            line: 21,
            column: 62
          }
        },
        loc: {
          start: {
            line: 21,
            column: 71
          },
          end: {
            line: 21,
            column: 103
          }
        },
        line: 21
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 41,
            column: 42
          },
          end: {
            line: 41,
            column: 43
          }
        },
        loc: {
          start: {
            line: 41,
            column: 62
          },
          end: {
            line: 49,
            column: 7
          }
        },
        line: 41
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 58,
            column: 31
          },
          end: {
            line: 58,
            column: 32
          }
        },
        loc: {
          start: {
            line: 58,
            column: 37
          },
          end: {
            line: 60,
            column: 3
          }
        },
        line: 58
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 74,
            column: 25
          },
          end: {
            line: 74,
            column: 26
          }
        },
        loc: {
          start: {
            line: 74,
            column: 41
          },
          end: {
            line: 79,
            column: 3
          }
        },
        line: 74
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 80,
            column: 24
          },
          end: {
            line: 80,
            column: 25
          }
        },
        loc: {
          start: {
            line: 80,
            column: 36
          },
          end: {
            line: 83,
            column: 3
          }
        },
        line: 80
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 84,
            column: 24
          },
          end: {
            line: 84,
            column: 25
          }
        },
        loc: {
          start: {
            line: 84,
            column: 36
          },
          end: {
            line: 94,
            column: 3
          }
        },
        line: 84
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 88,
            column: 58
          },
          end: {
            line: 88,
            column: 59
          }
        },
        loc: {
          start: {
            line: 88,
            column: 66
          },
          end: {
            line: 92,
            column: 5
          }
        },
        line: 88
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 90,
            column: 63
          },
          end: {
            line: 90,
            column: 64
          }
        },
        loc: {
          start: {
            line: 90,
            column: 80
          },
          end: {
            line: 90,
            column: 102
          }
        },
        line: 90
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 96,
            column: 22
          },
          end: {
            line: 96,
            column: 23
          }
        },
        loc: {
          start: {
            line: 96,
            column: 34
          },
          end: {
            line: 104,
            column: 3
          }
        },
        line: 96
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 99,
            column: 64
          },
          end: {
            line: 99,
            column: 65
          }
        },
        loc: {
          start: {
            line: 99,
            column: 72
          },
          end: {
            line: 102,
            column: 5
          }
        },
        line: 99
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 114,
            column: 62
          },
          end: {
            line: 114,
            column: 63
          }
        },
        loc: {
          start: {
            line: 114,
            column: 70
          },
          end: {
            line: 118,
            column: 3
          }
        },
        line: 114
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 123,
            column: 23
          },
          end: {
            line: 123,
            column: 24
          }
        },
        loc: {
          start: {
            line: 123,
            column: 35
          },
          end: {
            line: 127,
            column: 3
          }
        },
        line: 123
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 124,
            column: 65
          },
          end: {
            line: 124,
            column: 66
          }
        },
        loc: {
          start: {
            line: 124,
            column: 73
          },
          end: {
            line: 126,
            column: 5
          }
        },
        line: 124
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 128,
            column: 25
          },
          end: {
            line: 128,
            column: 26
          }
        },
        loc: {
          start: {
            line: 128,
            column: 42
          },
          end: {
            line: 132,
            column: 3
          }
        },
        line: 128
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 133,
            column: 25
          },
          end: {
            line: 133,
            column: 26
          }
        },
        loc: {
          start: {
            line: 133,
            column: 41
          },
          end: {
            line: 137,
            column: 3
          }
        },
        line: 133
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 138,
            column: 28
          },
          end: {
            line: 138,
            column: 29
          }
        },
        loc: {
          start: {
            line: 138,
            column: 40
          },
          end: {
            line: 149,
            column: 3
          }
        },
        line: 138
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 150,
            column: 27
          },
          end: {
            line: 150,
            column: 28
          }
        },
        loc: {
          start: {
            line: 150,
            column: 39
          },
          end: {
            line: 153,
            column: 3
          }
        },
        line: 150
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 154,
            column: 27
          },
          end: {
            line: 154,
            column: 28
          }
        },
        loc: {
          start: {
            line: 154,
            column: 43
          },
          end: {
            line: 183,
            column: 3
          }
        },
        line: 154
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 166,
            column: 7
          },
          end: {
            line: 166,
            column: 8
          }
        },
        loc: {
          start: {
            line: 166,
            column: 20
          },
          end: {
            line: 182,
            column: 5
          }
        },
        line: 166
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 171,
            column: 89
          },
          end: {
            line: 171,
            column: 90
          }
        },
        loc: {
          start: {
            line: 171,
            column: 97
          },
          end: {
            line: 181,
            column: 7
          }
        },
        line: 171
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 184,
            column: 26
          },
          end: {
            line: 184,
            column: 27
          }
        },
        loc: {
          start: {
            line: 184,
            column: 42
          },
          end: {
            line: 216,
            column: 3
          }
        },
        line: 184
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 196,
            column: 92
          },
          end: {
            line: 196,
            column: 93
          }
        },
        loc: {
          start: {
            line: 196,
            column: 100
          },
          end: {
            line: 215,
            column: 5
          }
        },
        line: 196
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 217,
            column: 24
          },
          end: {
            line: 217,
            column: 25
          }
        },
        loc: {
          start: {
            line: 217,
            column: 40
          },
          end: {
            line: 219,
            column: 3
          }
        },
        line: 217
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 220,
            column: 28
          },
          end: {
            line: 220,
            column: 29
          }
        },
        loc: {
          start: {
            line: 220,
            column: 40
          },
          end: {
            line: 230,
            column: 3
          }
        },
        line: 220
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 231,
            column: 24
          },
          end: {
            line: 231,
            column: 25
          }
        },
        loc: {
          start: {
            line: 231,
            column: 37
          },
          end: {
            line: 245,
            column: 3
          }
        },
        line: 231
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 233,
            column: 66
          },
          end: {
            line: 233,
            column: 67
          }
        },
        loc: {
          start: {
            line: 233,
            column: 74
          },
          end: {
            line: 244,
            column: 5
          }
        },
        line: 233
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 254,
            column: 31
          },
          end: {
            line: 254,
            column: 32
          }
        },
        loc: {
          start: {
            line: 254,
            column: 51
          },
          end: {
            line: 258,
            column: 3
          }
        },
        line: 254
      },
      '31': {
        name: '(anonymous_31)',
        decl: {
          start: {
            line: 259,
            column: 27
          },
          end: {
            line: 259,
            column: 28
          }
        },
        loc: {
          start: {
            line: 259,
            column: 74
          },
          end: {
            line: 278,
            column: 3
          }
        },
        line: 259
      },
      '32': {
        name: '(anonymous_32)',
        decl: {
          start: {
            line: 275,
            column: 98
          },
          end: {
            line: 275,
            column: 99
          }
        },
        loc: {
          start: {
            line: 275,
            column: 106
          },
          end: {
            line: 277,
            column: 5
          }
        },
        line: 275
      },
      '33': {
        name: '(anonymous_33)',
        decl: {
          start: {
            line: 279,
            column: 25
          },
          end: {
            line: 279,
            column: 26
          }
        },
        loc: {
          start: {
            line: 279,
            column: 72
          },
          end: {
            line: 280,
            column: 3
          }
        },
        line: 279
      },
      '34': {
        name: '(anonymous_34)',
        decl: {
          start: {
            line: 283,
            column: 16
          },
          end: {
            line: 283,
            column: 17
          }
        },
        loc: {
          start: {
            line: 283,
            column: 38
          },
          end: {
            line: 364,
            column: 3
          }
        },
        line: 283
      },
      '35': {
        name: 'logical_planCtrl_notification',
        decl: {
          start: {
            line: 366,
            column: 11
          },
          end: {
            line: 366,
            column: 40
          }
        },
        loc: {
          start: {
            line: 366,
            column: 115
          },
          end: {
            line: 371,
            column: 3
          }
        },
        line: 366
      },
      '36': {
        name: '(anonymous_36)',
        decl: {
          start: {
            line: 368,
            column: 24
          },
          end: {
            line: 368,
            column: 25
          }
        },
        loc: {
          start: {
            line: 368,
            column: 36
          },
          end: {
            line: 370,
            column: 5
          }
        },
        line: 368
      },
      '37': {
        name: '(anonymous_37)',
        decl: {
          start: {
            line: 373,
            column: 28
          },
          end: {
            line: 373,
            column: 29
          }
        },
        loc: {
          start: {
            line: 373,
            column: 40
          },
          end: {
            line: 382,
            column: 3
          }
        },
        line: 373
      },
      '38': {
        name: '(anonymous_38)',
        decl: {
          start: {
            line: 384,
            column: 22
          },
          end: {
            line: 384,
            column: 23
          }
        },
        loc: {
          start: {
            line: 384,
            column: 38
          },
          end: {
            line: 439,
            column: 3
          }
        },
        line: 384
      },
      '39': {
        name: '(anonymous_39)',
        decl: {
          start: {
            line: 397,
            column: 7
          },
          end: {
            line: 397,
            column: 8
          }
        },
        loc: {
          start: {
            line: 397,
            column: 20
          },
          end: {
            line: 438,
            column: 5
          }
        },
        line: 397
      },
      '40': {
        name: '(anonymous_40)',
        decl: {
          start: {
            line: 410,
            column: 126
          },
          end: {
            line: 410,
            column: 127
          }
        },
        loc: {
          start: {
            line: 410,
            column: 134
          },
          end: {
            line: 437,
            column: 7
          }
        },
        line: 410
      },
      '41': {
        name: '(anonymous_41)',
        decl: {
          start: {
            line: 440,
            column: 22
          },
          end: {
            line: 440,
            column: 23
          }
        },
        loc: {
          start: {
            line: 440,
            column: 34
          },
          end: {
            line: 443,
            column: 3
          }
        },
        line: 440
      },
      '42': {
        name: '(anonymous_42)',
        decl: {
          start: {
            line: 445,
            column: 22
          },
          end: {
            line: 445,
            column: 23
          }
        },
        loc: {
          start: {
            line: 445,
            column: 31
          },
          end: {
            line: 451,
            column: 3
          }
        },
        line: 445
      },
      '43': {
        name: 'logical_planCtrl_edit',
        decl: {
          start: {
            line: 457,
            column: 9
          },
          end: {
            line: 457,
            column: 30
          }
        },
        loc: {
          start: {
            line: 457,
            column: 118
          },
          end: {
            line: 544,
            column: 1
          }
        },
        line: 457
      },
      '44': {
        name: '(anonymous_44)',
        decl: {
          start: {
            line: 458,
            column: 22
          },
          end: {
            line: 458,
            column: 23
          }
        },
        loc: {
          start: {
            line: 458,
            column: 34
          },
          end: {
            line: 461,
            column: 3
          }
        },
        line: 458
      },
      '45': {
        name: '(anonymous_45)',
        decl: {
          start: {
            line: 462,
            column: 24
          },
          end: {
            line: 462,
            column: 25
          }
        },
        loc: {
          start: {
            line: 462,
            column: 40
          },
          end: {
            line: 464,
            column: 3
          }
        },
        line: 462
      },
      '46': {
        name: '(anonymous_46)',
        decl: {
          start: {
            line: 465,
            column: 21
          },
          end: {
            line: 465,
            column: 22
          }
        },
        loc: {
          start: {
            line: 465,
            column: 33
          },
          end: {
            line: 474,
            column: 3
          }
        },
        line: 465
      },
      '47': {
        name: '(anonymous_47)',
        decl: {
          start: {
            line: 475,
            column: 24
          },
          end: {
            line: 475,
            column: 25
          }
        },
        loc: {
          start: {
            line: 475,
            column: 42
          },
          end: {
            line: 543,
            column: 3
          }
        },
        line: 475
      },
      '48': {
        name: '(anonymous_48)',
        decl: {
          start: {
            line: 509,
            column: 88
          },
          end: {
            line: 509,
            column: 89
          }
        },
        loc: {
          start: {
            line: 509,
            column: 96
          },
          end: {
            line: 542,
            column: 5
          }
        },
        line: 509
      },
      '49': {
        name: 'logical_levelCtrl',
        decl: {
          start: {
            line: 545,
            column: 9
          },
          end: {
            line: 545,
            column: 26
          }
        },
        loc: {
          start: {
            line: 545,
            column: 77
          },
          end: {
            line: 551,
            column: 1
          }
        },
        line: 545
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        }, {
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        }],
        line: 6
      },
      '1': {
        loc: {
          start: {
            line: 23,
            column: 8
          },
          end: {
            line: 37,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 23,
            column: 8
          },
          end: {
            line: 37,
            column: 9
          }
        }, {
          start: {
            line: 23,
            column: 8
          },
          end: {
            line: 37,
            column: 9
          }
        }],
        line: 23
      },
      '2': {
        loc: {
          start: {
            line: 42,
            column: 8
          },
          end: {
            line: 44,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 42,
            column: 8
          },
          end: {
            line: 44,
            column: 9
          }
        }, {
          start: {
            line: 42,
            column: 8
          },
          end: {
            line: 44,
            column: 9
          }
        }],
        line: 42
      },
      '3': {
        loc: {
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 47,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 47,
            column: 9
          }
        }, {
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 47,
            column: 9
          }
        }],
        line: 45
      },
      '4': {
        loc: {
          start: {
            line: 59,
            column: 79
          },
          end: {
            line: 59,
            column: 125
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 59,
            column: 120
          },
          end: {
            line: 59,
            column: 121
          }
        }, {
          start: {
            line: 59,
            column: 124
          },
          end: {
            line: 59,
            column: 125
          }
        }],
        line: 59
      },
      '5': {
        loc: {
          start: {
            line: 75,
            column: 4
          },
          end: {
            line: 77,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 75,
            column: 4
          },
          end: {
            line: 77,
            column: 5
          }
        }, {
          start: {
            line: 75,
            column: 4
          },
          end: {
            line: 77,
            column: 5
          }
        }],
        line: 75
      },
      '6': {
        loc: {
          start: {
            line: 75,
            column: 8
          },
          end: {
            line: 75,
            column: 100
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 75,
            column: 8
          },
          end: {
            line: 75,
            column: 38
          }
        }, {
          start: {
            line: 75,
            column: 42
          },
          end: {
            line: 75,
            column: 68
          }
        }, {
          start: {
            line: 75,
            column: 72
          },
          end: {
            line: 75,
            column: 100
          }
        }],
        line: 75
      },
      '7': {
        loc: {
          start: {
            line: 129,
            column: 31
          },
          end: {
            line: 129,
            column: 95
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 129,
            column: 62
          },
          end: {
            line: 129,
            column: 87
          }
        }, {
          start: {
            line: 129,
            column: 90
          },
          end: {
            line: 129,
            column: 95
          }
        }],
        line: 129
      },
      '8': {
        loc: {
          start: {
            line: 167,
            column: 6
          },
          end: {
            line: 169,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 167,
            column: 6
          },
          end: {
            line: 169,
            column: 7
          }
        }, {
          start: {
            line: 167,
            column: 6
          },
          end: {
            line: 169,
            column: 7
          }
        }],
        line: 167
      },
      '9': {
        loc: {
          start: {
            line: 173,
            column: 8
          },
          end: {
            line: 179,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 173,
            column: 8
          },
          end: {
            line: 179,
            column: 9
          }
        }, {
          start: {
            line: 173,
            column: 8
          },
          end: {
            line: 179,
            column: 9
          }
        }],
        line: 173
      },
      '10': {
        loc: {
          start: {
            line: 189,
            column: 28
          },
          end: {
            line: 189,
            column: 81
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 189,
            column: 76
          },
          end: {
            line: 189,
            column: 77
          }
        }, {
          start: {
            line: 189,
            column: 80
          },
          end: {
            line: 189,
            column: 81
          }
        }],
        line: 189
      },
      '11': {
        loc: {
          start: {
            line: 189,
            column: 28
          },
          end: {
            line: 189,
            column: 73
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 189,
            column: 28
          },
          end: {
            line: 189,
            column: 49
          }
        }, {
          start: {
            line: 189,
            column: 53
          },
          end: {
            line: 189,
            column: 73
          }
        }],
        line: 189
      },
      '12': {
        loc: {
          start: {
            line: 191,
            column: 28
          },
          end: {
            line: 191,
            column: 57
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 191,
            column: 52
          },
          end: {
            line: 191,
            column: 53
          }
        }, {
          start: {
            line: 191,
            column: 56
          },
          end: {
            line: 191,
            column: 57
          }
        }],
        line: 191
      },
      '13': {
        loc: {
          start: {
            line: 198,
            column: 6
          },
          end: {
            line: 204,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 198,
            column: 6
          },
          end: {
            line: 204,
            column: 7
          }
        }, {
          start: {
            line: 198,
            column: 6
          },
          end: {
            line: 204,
            column: 7
          }
        }],
        line: 198
      },
      '14': {
        loc: {
          start: {
            line: 198,
            column: 10
          },
          end: {
            line: 198,
            column: 33
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 198,
            column: 10
          },
          end: {
            line: 198,
            column: 19
          }
        }, {
          start: {
            line: 198,
            column: 23
          },
          end: {
            line: 198,
            column: 33
          }
        }],
        line: 198
      },
      '15': {
        loc: {
          start: {
            line: 205,
            column: 6
          },
          end: {
            line: 211,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 205,
            column: 6
          },
          end: {
            line: 211,
            column: 7
          }
        }, {
          start: {
            line: 205,
            column: 6
          },
          end: {
            line: 211,
            column: 7
          }
        }],
        line: 205
      },
      '16': {
        loc: {
          start: {
            line: 234,
            column: 6
          },
          end: {
            line: 243,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 234,
            column: 6
          },
          end: {
            line: 243,
            column: 7
          }
        }, {
          start: {
            line: 234,
            column: 6
          },
          end: {
            line: 243,
            column: 7
          }
        }],
        line: 234
      },
      '17': {
        loc: {
          start: {
            line: 260,
            column: 4
          },
          end: {
            line: 274,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 260,
            column: 4
          },
          end: {
            line: 274,
            column: 5
          }
        }, {
          start: {
            line: 260,
            column: 4
          },
          end: {
            line: 274,
            column: 5
          }
        }],
        line: 260
      },
      '18': {
        loc: {
          start: {
            line: 262,
            column: 6
          },
          end: {
            line: 264,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 262,
            column: 6
          },
          end: {
            line: 264,
            column: 7
          }
        }, {
          start: {
            line: 262,
            column: 6
          },
          end: {
            line: 264,
            column: 7
          }
        }],
        line: 262
      },
      '19': {
        loc: {
          start: {
            line: 265,
            column: 6
          },
          end: {
            line: 267,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 265,
            column: 6
          },
          end: {
            line: 267,
            column: 7
          }
        }, {
          start: {
            line: 265,
            column: 6
          },
          end: {
            line: 267,
            column: 7
          }
        }],
        line: 265
      },
      '20': {
        loc: {
          start: {
            line: 287,
            column: 4
          },
          end: {
            line: 350,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 287,
            column: 4
          },
          end: {
            line: 350,
            column: 5
          }
        }, {
          start: {
            line: 287,
            column: 4
          },
          end: {
            line: 350,
            column: 5
          }
        }],
        line: 287
      },
      '21': {
        loc: {
          start: {
            line: 304,
            column: 11
          },
          end: {
            line: 350,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 304,
            column: 11
          },
          end: {
            line: 350,
            column: 5
          }
        }, {
          start: {
            line: 304,
            column: 11
          },
          end: {
            line: 350,
            column: 5
          }
        }],
        line: 304
      },
      '22': {
        loc: {
          start: {
            line: 321,
            column: 8
          },
          end: {
            line: 326,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 321,
            column: 8
          },
          end: {
            line: 326,
            column: 9
          }
        }, {
          start: {
            line: 321,
            column: 8
          },
          end: {
            line: 326,
            column: 9
          }
        }],
        line: 321
      },
      '23': {
        loc: {
          start: {
            line: 337,
            column: 18
          },
          end: {
            line: 337,
            column: 48
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 337,
            column: 18
          },
          end: {
            line: 337,
            column: 43
          }
        }, {
          start: {
            line: 337,
            column: 47
          },
          end: {
            line: 337,
            column: 48
          }
        }],
        line: 337
      },
      '24': {
        loc: {
          start: {
            line: 344,
            column: 8
          },
          end: {
            line: 348,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 344,
            column: 8
          },
          end: {
            line: 348,
            column: 9
          }
        }, {
          start: {
            line: 344,
            column: 8
          },
          end: {
            line: 348,
            column: 9
          }
        }],
        line: 344
      },
      '25': {
        loc: {
          start: {
            line: 398,
            column: 6
          },
          end: {
            line: 400,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 398,
            column: 6
          },
          end: {
            line: 400,
            column: 7
          }
        }, {
          start: {
            line: 398,
            column: 6
          },
          end: {
            line: 400,
            column: 7
          }
        }],
        line: 398
      },
      '26': {
        loc: {
          start: {
            line: 413,
            column: 8
          },
          end: {
            line: 434,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 413,
            column: 8
          },
          end: {
            line: 434,
            column: 9
          }
        }, {
          start: {
            line: 413,
            column: 8
          },
          end: {
            line: 434,
            column: 9
          }
        }],
        line: 413
      },
      '27': {
        loc: {
          start: {
            line: 418,
            column: 10
          },
          end: {
            line: 426,
            column: 11
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 419,
            column: 12
          },
          end: {
            line: 421,
            column: 20
          }
        }, {
          start: {
            line: 423,
            column: 12
          },
          end: {
            line: 425,
            column: 20
          }
        }],
        line: 418
      },
      '28': {
        loc: {
          start: {
            line: 467,
            column: 4
          },
          end: {
            line: 469,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 467,
            column: 4
          },
          end: {
            line: 469,
            column: 5
          }
        }, {
          start: {
            line: 467,
            column: 4
          },
          end: {
            line: 469,
            column: 5
          }
        }],
        line: 467
      },
      '29': {
        loc: {
          start: {
            line: 470,
            column: 4
          },
          end: {
            line: 472,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 470,
            column: 4
          },
          end: {
            line: 472,
            column: 5
          }
        }, {
          start: {
            line: 470,
            column: 4
          },
          end: {
            line: 472,
            column: 5
          }
        }],
        line: 470
      },
      '30': {
        loc: {
          start: {
            line: 470,
            column: 9
          },
          end: {
            line: 470,
            column: 31
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 470,
            column: 9
          },
          end: {
            line: 470,
            column: 25
          }
        }, {
          start: {
            line: 470,
            column: 29
          },
          end: {
            line: 470,
            column: 31
          }
        }],
        line: 470
      },
      '31': {
        loc: {
          start: {
            line: 484,
            column: 37
          },
          end: {
            line: 484,
            column: 137
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 484,
            column: 58
          },
          end: {
            line: 484,
            column: 82
          }
        }, {
          start: {
            line: 484,
            column: 86
          },
          end: {
            line: 484,
            column: 136
          }
        }],
        line: 484
      },
      '32': {
        loc: {
          start: {
            line: 493,
            column: 4
          },
          end: {
            line: 499,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 493,
            column: 4
          },
          end: {
            line: 499,
            column: 5
          }
        }, {
          start: {
            line: 493,
            column: 4
          },
          end: {
            line: 499,
            column: 5
          }
        }],
        line: 493
      },
      '33': {
        loc: {
          start: {
            line: 494,
            column: 6
          },
          end: {
            line: 497,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 494,
            column: 6
          },
          end: {
            line: 497,
            column: 7
          }
        }, {
          start: {
            line: 494,
            column: 6
          },
          end: {
            line: 497,
            column: 7
          }
        }],
        line: 494
      },
      '34': {
        loc: {
          start: {
            line: 501,
            column: 4
          },
          end: {
            line: 506,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 501,
            column: 4
          },
          end: {
            line: 506,
            column: 5
          }
        }, {
          start: {
            line: 501,
            column: 4
          },
          end: {
            line: 506,
            column: 5
          }
        }],
        line: 501
      },
      '35': {
        loc: {
          start: {
            line: 501,
            column: 7
          },
          end: {
            line: 501,
            column: 55
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 501,
            column: 7
          },
          end: {
            line: 501,
            column: 31
          }
        }, {
          start: {
            line: 501,
            column: 35
          },
          end: {
            line: 501,
            column: 55
          }
        }],
        line: 501
      },
      '36': {
        loc: {
          start: {
            line: 513,
            column: 6
          },
          end: {
            line: 515,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 513,
            column: 6
          },
          end: {
            line: 515,
            column: 7
          }
        }, {
          start: {
            line: 513,
            column: 6
          },
          end: {
            line: 515,
            column: 7
          }
        }],
        line: 513
      },
      '37': {
        loc: {
          start: {
            line: 516,
            column: 6
          },
          end: {
            line: 538,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 516,
            column: 6
          },
          end: {
            line: 538,
            column: 7
          }
        }, {
          start: {
            line: 516,
            column: 6
          },
          end: {
            line: 538,
            column: 7
          }
        }],
        line: 516
      },
      '38': {
        loc: {
          start: {
            line: 518,
            column: 8
          },
          end: {
            line: 532,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 518,
            column: 8
          },
          end: {
            line: 532,
            column: 9
          }
        }, {
          start: {
            line: 518,
            column: 8
          },
          end: {
            line: 532,
            column: 9
          }
        }],
        line: 518
      },
      '39': {
        loc: {
          start: {
            line: 524,
            column: 15
          },
          end: {
            line: 532,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 524,
            column: 15
          },
          end: {
            line: 532,
            column: 9
          }
        }, {
          start: {
            line: 524,
            column: 15
          },
          end: {
            line: 532,
            column: 9
          }
        }],
        line: 524
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0,
      '242': 0,
      '243': 0,
      '244': 0,
      '245': 0,
      '246': 0,
      '247': 0,
      '248': 0,
      '249': 0,
      '250': 0,
      '251': 0,
      '252': 0,
      '253': 0,
      '254': 0,
      '255': 0,
      '256': 0,
      '257': 0,
      '258': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_ofivefy5v.s[0]++;
/**
* Geographic Map - controller
*/
angular.module('app').controller('logical_planCtrl', function ($scope, SweetAlert, $deltaLogLevel, $deltaPlanLogique, $LOGINService, FileUploader, $rootScope, $translate, $CRUDService, $otherHttpService, $uibModal, $filter) {
  cov_ofivefy5v.f[0]++;
  cov_ofivefy5v.s[1]++;

  $rootScope.processPageRight('1_5');
  cov_ofivefy5v.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_ofivefy5v.b[0][0]++;
    cov_ofivefy5v.s[3]++;

    return;
  } else {
    cov_ofivefy5v.b[0][1]++;
  }
  cov_ofivefy5v.s[4]++;
  $scope.PATHNL = 'NiveauCl';
  cov_ofivefy5v.s[5]++;
  $scope.PATHPL = 'CadreLogique';
  cov_ofivefy5v.s[6]++;
  $scope.editLabelPlanLog = false;
  cov_ofivefy5v.s[7]++;
  $scope.openEditPersoLabel = function (value) {
    cov_ofivefy5v.f[1]++;
    cov_ofivefy5v.s[8]++;

    $scope.editLabelPlanLog = value;
  };
  cov_ofivefy5v.s[9]++;
  $scope.listDefaultStructure = [];
  cov_ofivefy5v.s[10]++;
  $deltaLogLevel.addController($scope, function () {
    cov_ofivefy5v.f[2]++;
    cov_ofivefy5v.s[11]++;

    $CRUDService.getAll($scope.PATHNL, { get: 'default_structure' }, function (data) {
      cov_ofivefy5v.f[3]++;

      var index__ = (cov_ofivefy5v.s[12]++, 0);

      cov_ofivefy5v.s[13]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;

          var item_s = (cov_ofivefy5v.s[14]++, $scope.listData_LogLevels_bksb.filter(function (level) {
            cov_ofivefy5v.f[4]++;
            cov_ofivefy5v.s[15]++;
            return level.Niveau_CL == index__ + 1;
          }));

          cov_ofivefy5v.s[16]++;
          if (item_s.length == 0) {
            cov_ofivefy5v.b[1][0]++;
            cov_ofivefy5v.s[17]++;

            $scope.listData_LogLevels_bksb.push({
              id: -(index__ + 1),
              LONG_CODE_NCL: 0,
              LIBELLE_NCL: item,
              ETAT_INDIC_NCL: 1,
              Niveau_CL: index__ + 1,
              PLAN_ACT_NCL: 0,
              ETAT_SUIVI_NCL: 0,
              ETAT_INDIC_TAUX: 0,
              ETAT_POIDS_AUTO: 0,
              TYPE_CALCUL_POIDS: 0,
              COLOR: '0,0,0'
            });
          } else {
            cov_ofivefy5v.b[1][1]++;
          }
          cov_ofivefy5v.s[18]++;
          index__++;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_ofivefy5v.s[19]++;
      $scope.listData_LogLevels_bksb.sort(function (levelA, levelB) {
        cov_ofivefy5v.f[5]++;
        cov_ofivefy5v.s[20]++;

        if (levelA.Niveau_CL > levelB.Niveau_CL) {
          cov_ofivefy5v.b[2][0]++;
          cov_ofivefy5v.s[21]++;

          return 1;
        } else {
          cov_ofivefy5v.b[2][1]++;
        }
        cov_ofivefy5v.s[22]++;
        if (levelA.Niveau_CL == levelB.Niveau_CL) {
          cov_ofivefy5v.b[3][0]++;
          cov_ofivefy5v.s[23]++;

          return 0;
        } else {
          cov_ofivefy5v.b[3][1]++;
        }
        cov_ofivefy5v.s[24]++;
        return -1;
      });

      cov_ofivefy5v.s[25]++;
      $scope.listDefaultStructure = data;
    });
  });
  cov_ofivefy5v.s[26]++;
  $deltaPlanLogique.addController($scope);

  cov_ofivefy5v.s[27]++;
  $scope.canPlanWeight = $rootScope.currentProject.project.ACTIVITY_WEIGHT == 1;

  cov_ofivefy5v.s[28]++;
  $scope.changeCrossCutting = function () {
    cov_ofivefy5v.f[6]++;
    cov_ofivefy5v.s[29]++;

    $CRUDService.save('Projet', { action: 'update_cross_cutting_manage', value: $scope.actionLabel.valueCrossIndicator ? (cov_ofivefy5v.b[4][0]++, 1) : (cov_ofivefy5v.b[4][1]++, 0) });
  };

  cov_ofivefy5v.s[30]++;
  $scope.PATH = 'Periode';
  var EXPAND = (cov_ofivefy5v.s[31]++, 'fa-angle-down');
  var COLLAPSE = (cov_ofivefy5v.s[32]++, 'fa-angle-right');
  var LEAF = (cov_ofivefy5v.s[33]++, 'fa-circle');
  var LOADING = (cov_ofivefy5v.s[34]++, 'fa-spinner');
  cov_ofivefy5v.s[35]++;
  $scope.idScope = $scope.$id;
  // Champ Personnalisé
  cov_ofivefy5v.s[36]++;
  $scope.PATHCHAMP = 'ChampsPerso';

  cov_ofivefy5v.s[37]++;
  $scope.showNiveauLog = false;
  cov_ofivefy5v.s[38]++;
  $scope.isloading = true;
  cov_ofivefy5v.s[39]++;
  $scope.listLocalite = [];
  cov_ofivefy5v.s[40]++;
  $scope.getLevelLabel = function (item) {
    cov_ofivefy5v.f[7]++;
    cov_ofivefy5v.s[41]++;

    if ((cov_ofivefy5v.b[6][0]++, $CRUDService.getLang() == 'en') && (cov_ofivefy5v.b[6][1]++, item.LIBELLE_NCL_ENG != '') && (cov_ofivefy5v.b[6][2]++, item.LIBELLE_NCL_ENG != null)) {
      cov_ofivefy5v.b[5][0]++;
      cov_ofivefy5v.s[42]++;

      return item.LIBELLE_NCL_ENG;
    } else {
      cov_ofivefy5v.b[5][1]++;
    }
    cov_ofivefy5v.s[43]++;
    return item.LIBELLE_NCL;
  };
  cov_ofivefy5v.s[44]++;
  $scope.hidesubPanel = function () {
    cov_ofivefy5v.f[8]++;
    cov_ofivefy5v.s[45]++;

    $scope.show_logicallevel = false;
    cov_ofivefy5v.s[46]++;
    $scope.show_logicalplan = false;
  };
  cov_ofivefy5v.s[47]++;
  $scope.getPoidsList = function () {
    cov_ofivefy5v.f[9]++;
    cov_ofivefy5v.s[48]++;

    $scope.listPoids = [];
    cov_ofivefy5v.s[49]++;
    $scope.sumPoids = 0;
    cov_ofivefy5v.s[50]++;
    $scope.getProject();
    cov_ofivefy5v.s[51]++;
    $CRUDService.getAll($scope.PATHPL, { get: 'allpoids' }, function (data) {
      cov_ofivefy5v.f[10]++;
      cov_ofivefy5v.s[52]++;

      $scope.listPoids = data;
      cov_ofivefy5v.s[53]++;
      $scope.sumPoids = Object.values($scope.listPoids).reduce(function (acc, valeur) {
        cov_ofivefy5v.f[11]++;
        cov_ofivefy5v.s[54]++;
        return acc + parseInt(valeur);
      }, 0);
      //console.log($scope.sumPoids);
    });
  };

  cov_ofivefy5v.s[55]++;
  $scope.getProject = function () {
    cov_ofivefy5v.f[12]++;
    cov_ofivefy5v.s[56]++;

    $scope.project = {};
    cov_ofivefy5v.s[57]++;
    $scope.isPondActive;
    cov_ofivefy5v.s[58]++;
    $CRUDService.getAll($scope.PATHPL, { get: 'currentProject' }, function (data) {
      cov_ofivefy5v.f[13]++;
      cov_ofivefy5v.s[59]++;

      $scope.project = data;
      cov_ofivefy5v.s[60]++;
      $scope.isPondActive = $scope.project.ACTIVITY_WEIGHT;
    });
  };

  cov_ofivefy5v.s[61]++;
  $scope.actionLabel = {
    ADD: $translate.instant('LOGICAL_PLAN.ADD'),
    LEVEL: $translate.instant('MENU.ST'),
    IMPORT: $translate.instant('LOGICAL_PLAN.IMPORT')
  };

  cov_ofivefy5v.s[62]++;
  $scope.canDisplayCrossIndicator = false;

  cov_ofivefy5v.s[63]++;
  $CRUDService.getAll('Projet', { get: 'cross_cutting_state' }, function (data) {
    cov_ofivefy5v.f[14]++;
    cov_ofivefy5v.s[64]++;

    // eslint-disable-next-line radix
    $scope.canDisplayCrossIndicator = data.display == 1;
    cov_ofivefy5v.s[65]++;
    $scope.actionLabel.valueCrossIndicator = data.value == 1;
  });

  // Niveau logique
  cov_ofivefy5v.s[66]++;
  $scope.itemSelectedNL = {};
  cov_ofivefy5v.s[67]++;
  $scope.hideAdd = true;
  cov_ofivefy5v.s[68]++;
  $scope.canAddNewLG = function () {
    cov_ofivefy5v.f[15]++;
    cov_ofivefy5v.s[69]++;

    $otherHttpService.post($scope.PATHNL, { action: 'canAddNew' }, function (data) {
      cov_ofivefy5v.f[16]++;
      cov_ofivefy5v.s[70]++;

      $scope.hideAdd = data != 0;
    });
  };
  cov_ofivefy5v.s[71]++;
  $scope.showNiveauLog = function (value) {
    cov_ofivefy5v.f[17]++;
    cov_ofivefy5v.s[72]++;

    $scope.show_logicallevel = angular.isUndefined(value) ? (cov_ofivefy5v.b[7][0]++, !$scope.show_logicallevel) : (cov_ofivefy5v.b[7][1]++, value);
    cov_ofivefy5v.s[73]++;
    $scope.show_logicalplan = false;
    cov_ofivefy5v.s[74]++;
    $scope.cancelNiveauLog();
  };
  cov_ofivefy5v.s[75]++;
  $scope.editNiveauLog = function (item) {
    cov_ofivefy5v.f[18]++;
    cov_ofivefy5v.s[76]++;

    item.hideEditor = false;
    cov_ofivefy5v.s[77]++;
    $scope.beginEditNL = true;
    cov_ofivefy5v.s[78]++;
    $scope.itemSelectedNL = angular.copy(item);
  };
  cov_ofivefy5v.s[79]++;
  $scope.editnewNiveauLog = function () {
    cov_ofivefy5v.f[19]++;
    cov_ofivefy5v.s[80]++;

    $scope.editNiveauLog({
      id: 0,
      COLOR: '0,0,0',
      LONG_CODE_NCL: 1,
      ID_PROJET: $rootScope.currentProject.project.id,
      Niveau_CL: $scope.listData_LogLevels_bksb.length + 1,
      ETAT_INDIC_NCL: true,
      TYPE_CALCUL_POIDS: 0,
      ETAT_POIDS_AUTO: false
    });
  };
  cov_ofivefy5v.s[81]++;
  $scope.cancelNiveauLog = function () {
    cov_ofivefy5v.f[20]++;
    cov_ofivefy5v.s[82]++;

    $scope.beginEditNL = false;
    cov_ofivefy5v.s[83]++;
    $scope.itemSelectedNL = null;
  };
  cov_ofivefy5v.s[84]++;
  $scope.deleteNiveauLog = function (item) {
    cov_ofivefy5v.f[21]++;
    cov_ofivefy5v.s[85]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("LOGICAL_PLAN.CONFIRM_DELETE_LEVEL"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_ofivefy5v.f[22]++;
      cov_ofivefy5v.s[86]++;

      if (isconfirm) {
        cov_ofivefy5v.b[8][0]++;
        cov_ofivefy5v.s[87]++;

        return;
      } else {
        cov_ofivefy5v.b[8][1]++;
      }
      cov_ofivefy5v.s[88]++;
      $scope.isloadingNL_bksb = true;
      cov_ofivefy5v.s[89]++;
      $CRUDService.delet($scope.PATHNL, { action: 'supp', valeur: $filter('json')(item) }, function (data) {
        cov_ofivefy5v.f[23]++;
        cov_ofivefy5v.s[90]++;

        $scope.isloadingNL_bksb = false;
        cov_ofivefy5v.s[91]++;
        if (data < 1) {
          cov_ofivefy5v.b[9][0]++;
          cov_ofivefy5v.s[92]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_ofivefy5v.s[93]++;
          return;
        } else {
          cov_ofivefy5v.b[9][1]++;
        }
        cov_ofivefy5v.s[94]++;
        $scope.loadingLogLevelData_bksb();
      });
    });
  };
  cov_ofivefy5v.s[95]++;
  $scope.validateItemNL = function (item) {
    cov_ofivefy5v.f[24]++;

    var itemp = (cov_ofivefy5v.s[96]++, angular.copy(item));
    cov_ofivefy5v.s[97]++;
    $scope.isloadingNL_bksb = true;
    cov_ofivefy5v.s[98]++;
    itemp.PLAN_ACT_NCL = 0;
    cov_ofivefy5v.s[99]++;
    itemp.ETAT_RISQUE_NCL = 0;
    cov_ofivefy5v.s[100]++;
    itemp.ETAT_INDIC_TAUX = (cov_ofivefy5v.b[11][0]++, itemp.ETAT_INDIC_TAUX) && (cov_ofivefy5v.b[11][1]++, itemp.ETAT_INDIC_NCL) ? (cov_ofivefy5v.b[10][0]++, 1) : (cov_ofivefy5v.b[10][1]++, 0);
    cov_ofivefy5v.s[101]++;
    itemp.ETAT_INDIC_NCL = 1; // itemp.ETAT_INDIC_NCL ? 1 : 0;
    cov_ofivefy5v.s[102]++;
    itemp.ETAT_POIDS_AUTO = itemp.ETAT_POIDS_AUTO ? (cov_ofivefy5v.b[12][0]++, 1) : (cov_ofivefy5v.b[12][1]++, 0);
    cov_ofivefy5v.s[103]++;
    $scope.nivclError = {
      label: '',
      id: null
    };
    cov_ofivefy5v.s[104]++;
    $CRUDService.save($scope.PATHNL, { action: 'miseAJour', valeur: $filter('json')(itemp) }, function (data) {
      cov_ofivefy5v.f[25]++;
      cov_ofivefy5v.s[105]++;

      $scope.isloadingNL_bksb = false;
      cov_ofivefy5v.s[106]++;
      if ((cov_ofivefy5v.b[14][0]++, data == 0) || (cov_ofivefy5v.b[14][1]++, data == -2)) {
        cov_ofivefy5v.b[13][0]++;
        cov_ofivefy5v.s[107]++;

        $scope.nivclError = {
          label: $translate.instant('LOGICAL_PLAN.ERROR_UPDATE_LONG'),
          id: 1
        };
        cov_ofivefy5v.s[108]++;
        return;
      } else {
        cov_ofivefy5v.b[13][1]++;
      }
      cov_ofivefy5v.s[109]++;
      if (data < 1) {
        cov_ofivefy5v.b[15][0]++;
        cov_ofivefy5v.s[110]++;

        $scope.nivclError = {
          label: $translate.instant('COMMON.TITLE_ERROR_ADD'),
          id: 1
        };
        cov_ofivefy5v.s[111]++;
        return;
      } else {
        cov_ofivefy5v.b[15][1]++;
      }
      cov_ofivefy5v.s[112]++;
      $scope.cancelNiveauLog();
      cov_ofivefy5v.s[113]++;
      $scope.loadingLogLevelData_bksb();
      cov_ofivefy5v.s[114]++;
      $scope.getPoidsList();
    });
  };
  cov_ofivefy5v.s[115]++;
  $scope.selectItemNL = function (item) {
    cov_ofivefy5v.f[26]++;
    cov_ofivefy5v.s[116]++;

    $scope.itemSelectedNL = angular.copy(item);
  };
  cov_ofivefy5v.s[117]++;
  $scope.openLogStructure = function () {
    cov_ofivefy5v.f[27]++;
    cov_ofivefy5v.s[118]++;

    $scope.cancelNiveauLog();
    cov_ofivefy5v.s[119]++;
    $uibModal.open({
      templateUrl: 'app/views/logical_plan/editLevel.html',
      controller: logical_levelCtrl,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };
  cov_ofivefy5v.s[120]++;
  $scope.updateByFile = function (l) {
    cov_ofivefy5v.f[28]++;
    cov_ofivefy5v.s[121]++;

    $scope.loadingUploadLevel = l;
    cov_ofivefy5v.s[122]++;
    $CRUDService.save($scope.PATHPL, { action: 'updateByFile', l: l }, function (data) {
      cov_ofivefy5v.f[29]++;
      cov_ofivefy5v.s[123]++;

      if (data == 0) {
        cov_ofivefy5v.b[16][0]++;
        cov_ofivefy5v.s[124]++;

        $deltaLogLevel.clearData();
        cov_ofivefy5v.s[125]++;
        $deltaLogLevel.addController($scope);
        cov_ofivefy5v.s[126]++;
        $deltaPlanLogique.removeAllData();
        cov_ofivefy5v.s[127]++;
        $deltaPlanLogique.addController($scope);
        cov_ofivefy5v.s[128]++;
        $scope.loadingUpload = false;
        cov_ofivefy5v.s[129]++;
        $scope.loadingUploadLevel = 0;
      } else {
        cov_ofivefy5v.b[16][1]++;
        cov_ofivefy5v.s[130]++;

        $scope.updateByFile(l + 1);
      }
    });
  };
  // uploadfile
  var uploader = (cov_ofivefy5v.s[131]++, $scope.uploader = new FileUploader({
    headers: $CRUDService.getHeader(),
    url: $CRUDService.getServerAdress() + 'web_server.php?action=loadcsvFile&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + $scope.PATHNL,
    alias: 'fileCVSPlanLog',
    removeAfterUpload: true
  }));

  cov_ofivefy5v.s[132]++;
  uploader.onAfterAddingFile = function (fileItem) {
    cov_ofivefy5v.f[30]++;
    cov_ofivefy5v.s[133]++;

    $scope.loadingUpload = true;
    cov_ofivefy5v.s[134]++;
    $scope.uploadProgress = 0;
    cov_ofivefy5v.s[135]++;
    fileItem.upload();
  };
  cov_ofivefy5v.s[136]++;
  uploader.onSuccessItem = function (fileItem, response, status, headers) {
    cov_ofivefy5v.f[31]++;
    cov_ofivefy5v.s[137]++;

    if (response.f == '') {
      cov_ofivefy5v.b[17][0]++;

      var labelError = (cov_ofivefy5v.s[138]++, 'LOGICAL_PLAN.ERROR_STRUC');
      cov_ofivefy5v.s[139]++;
      if (response.s == 1) {
        cov_ofivefy5v.b[18][0]++;
        cov_ofivefy5v.s[140]++;

        labelError = 'LOGICAL_PLAN.ERROR_FORMAT';
      } else {
        cov_ofivefy5v.b[18][1]++;
      }
      cov_ofivefy5v.s[141]++;
      if (response.s == 2) {
        cov_ofivefy5v.b[19][0]++;
        cov_ofivefy5v.s[142]++;

        labelError = 'LOGICAL_PLAN.ERROR_CANADD';
      } else {
        cov_ofivefy5v.b[19][1]++;
      }
      cov_ofivefy5v.s[143]++;
      SweetAlert.swal({
        title: $translate.instant('COMMON.ERROR_'),
        text: $translate.instant(labelError)
      });
      cov_ofivefy5v.s[144]++;
      $scope.loadingUpload = false;
      cov_ofivefy5v.s[145]++;
      return;
    } else {
      cov_ofivefy5v.b[17][1]++;
    }
    cov_ofivefy5v.s[146]++;
    $CRUDService.save($scope.PATHNL, { action: 'miseAJourNclFile', lang: $LOGINService.getLang() }, function (data) {
      cov_ofivefy5v.f[32]++;
      cov_ofivefy5v.s[147]++;

      $scope.updateByFile(1);
    });
  };
  cov_ofivefy5v.s[148]++;
  uploader.onErrorItem = function (fileItem, response, status, headers) {
    cov_ofivefy5v.f[33]++;
  };
  // Plan Logique
  cov_ofivefy5v.s[149]++;
  $scope.planItem = {};
  cov_ofivefy5v.s[150]++;
  $scope.edit = function (item, fils) {
    cov_ofivefy5v.f[34]++;

    var el = (cov_ofivefy5v.s[151]++, {});
    cov_ofivefy5v.s[152]++;
    $scope.hidesubPanel();
    cov_ofivefy5v.s[153]++;
    $scope.show_logicalplan = true;
    cov_ofivefy5v.s[154]++;
    if (!item) {
      cov_ofivefy5v.b[20][0]++;
      cov_ofivefy5v.s[155]++;
      // Ajouter first level
      el = {
        LIBELLE_L_CL: '',
        LIBELLE_C_CL: '',
        LIBELLE_L_CL_EN: '',
        LIBELLE_C_CL_EN: '',
        CODE_CLCAFF: '',
        CODE_CLC: '',
        FIXE: '',
        POIDS_CL: 1,
        NC: $scope.listData_LogLevels_bksb[0],
        CODE_CLP: null,
        CODE_NCL: $scope.listData_LogLevels_bksb[0].id,
        expanded: false,
        id: 0,
        leaf: $scope.listData_LogLevels_bksb.length == 1
      };
    } else {
        cov_ofivefy5v.b[20][1]++;
        cov_ofivefy5v.s[156]++;
        if (fils) {
          cov_ofivefy5v.b[21][0]++;
          cov_ofivefy5v.s[157]++;
          // add fils
          el = {
            LIBELLE_L_CL_EN: '',
            LIBELLE_C_CL_EN: '',
            LIBELLE_L_CL: '',
            CODE_CLCAFF: '',
            CODE_CLC: '',
            FIXE: item.CODE_CLC,
            POIDS_CL: 1,
            LIBELLE_C_CL: '',
            NC: null,
            CODE_CLP: item.id,
            CODE_NCL: null,
            expanded: false,
            id: 0
          };
          cov_ofivefy5v.s[158]++;
          for (var i = 0; i < $scope.listData_LogLevels_bksb.length; i++) {
            cov_ofivefy5v.s[159]++;

            if ($scope.listData_LogLevels_bksb[i].id == item.CODE_NCL) {
              cov_ofivefy5v.b[22][0]++;
              cov_ofivefy5v.s[160]++;

              el.CODE_NCL = $scope.listData_LogLevels_bksb[i + 1].id;
              cov_ofivefy5v.s[161]++;
              el.NC = $scope.listData_LogLevels_bksb[i + 1];
              cov_ofivefy5v.s[162]++;
              el.leaf = i + 2 == $scope.listData_LogLevels_bksb.length;
              cov_ofivefy5v.s[163]++;
              break;
            } else {
              cov_ofivefy5v.b[22][1]++;
            }
          }
        } else {
          cov_ofivefy5v.b[21][1]++;
          cov_ofivefy5v.s[164]++;
          // Update
          el = {
            FIXE: '',
            LIBELLE_L_CL: item.LIBELLE_L_CL,
            LIBELLE_C_CL_EN: item.LIBELLE_C_CL_EN,
            LIBELLE_L_CL_EN: item.LIBELLE_L_CL_EN,
            LIBELLE_C_CL: item.LIBELLE_C_CL,
            CODE_CLCAFF: item.CODE_CLCAFF,
            CODE_CLC: item.CODE_CLC,
            POIDS_CL: (cov_ofivefy5v.b[23][0]++, parseFloat(item.POIDS_CL)) || (cov_ofivefy5v.b[23][1]++, 1),
            CODE_CLP: item.CODE_CLP,
            CODE_NCL: item.CODE_NCL,
            expanded: item.expanded,
            id: item.id
          };
          cov_ofivefy5v.s[165]++;
          for (var _i = 0; _i < $scope.listData_LogLevels_bksb.length; _i++) {
            cov_ofivefy5v.s[166]++;

            if ($scope.listData_LogLevels_bksb[_i].id == item.CODE_NCL) {
              cov_ofivefy5v.b[24][0]++;
              cov_ofivefy5v.s[167]++;

              el.NC = $scope.listData_LogLevels_bksb[_i];
              cov_ofivefy5v.s[168]++;
              el.leaf = _i + 1 == $scope.listData_LogLevels_bksb.length;
              cov_ofivefy5v.s[169]++;
              break;
            } else {
              cov_ofivefy5v.b[24][1]++;
            }
          }
        }
      }cov_ofivefy5v.s[170]++;
    $scope.editParam = {
      value: el.NC.LONG_CODE_NCL
    };
    cov_ofivefy5v.s[171]++;
    $scope.planItem = el;

    cov_ofivefy5v.s[172]++;
    $uibModal.open({
      templateUrl: 'app/views/logical_plan/edit.html',
      controller: logical_planCtrl_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  function logical_planCtrl_notification($scope, $uibModalInstance, $filter, $CRUDService, SweetAlert, $translate) {
    cov_ofivefy5v.f[35]++;
    cov_ofivefy5v.s[173]++;


    $scope.closeModal = function () {
      cov_ofivefy5v.f[36]++;
      cov_ofivefy5v.s[174]++;

      $uibModalInstance.dismiss('cancel');
    };
  }

  cov_ofivefy5v.s[175]++;
  $scope.openNotification = function () {
    cov_ofivefy5v.f[37]++;
    cov_ofivefy5v.s[176]++;

    $uibModal.open({
      templateUrl: 'app/views/logical_plan/notificationWeight.html',
      controller: logical_planCtrl_notification,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_ofivefy5v.s[177]++;
  $scope.deleteItem = function (item) {
    cov_ofivefy5v.f[38]++;

    var lasticone = (cov_ofivefy5v.s[178]++, item.icon);
    cov_ofivefy5v.s[179]++;
    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("LOGICAL_PLAN.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_ofivefy5v.f[39]++;
      cov_ofivefy5v.s[180]++;

      if (isconfirm) {
        cov_ofivefy5v.b[25][0]++;
        cov_ofivefy5v.s[181]++;

        return;
      } else {
        cov_ofivefy5v.b[25][1]++;
      }
      cov_ofivefy5v.s[182]++;
      item.icon = LOADING;
      var validateItem = (cov_ofivefy5v.s[183]++, {
        CODE_CLCAFF: item.CODE_CLCAFF,
        CODE_CLC: item.CODE_CLC,
        CODE_CLP: item.CODE_CLP,
        CODE_NCL: item.CODE_NCL,
        id: item.id
      });

      cov_ofivefy5v.s[184]++;
      $CRUDService.delet($scope.PATHPL, { action: 'supp_partial', id: validateItem.id, valeur: $filter('json')(validateItem) }, function (data) {
        cov_ofivefy5v.f[40]++;
        cov_ofivefy5v.s[185]++;

        item.icon = lasticone;
        cov_ofivefy5v.s[186]++;
        data = parseInt(data, 10);
        cov_ofivefy5v.s[187]++;
        if (data < 0) {
          cov_ofivefy5v.b[26][0]++;
          cov_ofivefy5v.s[188]++;

          $scope.isloading = false;

          var msg = (cov_ofivefy5v.s[189]++, $translate.instant('LOGICAL_PLAN.ERROR.DELETE'));

          cov_ofivefy5v.s[190]++;
          switch (data) {
            case -2:
              cov_ofivefy5v.b[27][0]++;
              cov_ofivefy5v.s[191]++;

              msg = $translate.instant('LOGICAL_PLAN.ERROR.DELETE_ACTIVITY');
              cov_ofivefy5v.s[192]++;
              break;

            case -3:
              cov_ofivefy5v.b[27][1]++;
              cov_ofivefy5v.s[193]++;

              msg = $translate.instant('LOGICAL_PLAN.ERROR.DELETE_INDICATOR');
              cov_ofivefy5v.s[194]++;
              break;
          }

          cov_ofivefy5v.s[195]++;
          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: msg
          });

          cov_ofivefy5v.s[196]++;
          return;
        } else {
          cov_ofivefy5v.b[26][1]++;
        }
        cov_ofivefy5v.s[197]++;
        $scope.getPoidsList();
        cov_ofivefy5v.s[198]++;
        $deltaPlanLogique.removeData(item);
      });
    });
  };
  cov_ofivefy5v.s[199]++;
  $scope.cancelItem = function () {
    cov_ofivefy5v.f[41]++;
    cov_ofivefy5v.s[200]++;

    $scope.planItem = {};
    cov_ofivefy5v.s[201]++;
    $scope.show_logicalplan = false;
  };

  cov_ofivefy5v.s[202]++;
  $scope.printData = function (label) {
    cov_ofivefy5v.f[42]++;
    cov_ofivefy5v.s[203]++;

    $rootScope.printReport({
      link: $scope.PATHPL,
      params: { get: 'print' },
      title: label
    });
  };

  cov_ofivefy5v.s[204]++;
  $scope.canAddNewLG();
  cov_ofivefy5v.s[205]++;
  $scope.getPoidsList();
});

function logical_planCtrl_edit($scope, $uibModalInstance, $filter, $log, $CRUDService, $translate, $deltaPlanLogique) {
  cov_ofivefy5v.f[43]++;
  cov_ofivefy5v.s[206]++;

  $scope.closeModal = function () {
    cov_ofivefy5v.f[44]++;
    cov_ofivefy5v.s[207]++;

    $scope.cancelItem();
    cov_ofivefy5v.s[208]++;
    $uibModalInstance.dismiss('cancel');
  };
  cov_ofivefy5v.s[209]++;
  $scope.getCodeError = function (item) {
    cov_ofivefy5v.f[45]++;
    cov_ofivefy5v.s[210]++;

    return $translate.instant('COMMON.ERROR.CODE_ERROR', $scope.editParam);
  };
  cov_ofivefy5v.s[211]++;
  $scope.checkCode = function () {
    cov_ofivefy5v.f[46]++;

    var item = (cov_ofivefy5v.s[212]++, $scope.planItem);
    cov_ofivefy5v.s[213]++;
    if (item.id != 0) {
      cov_ofivefy5v.b[28][0]++;
      cov_ofivefy5v.s[214]++;

      return true;
    } else {
      cov_ofivefy5v.b[28][1]++;
    }
    cov_ofivefy5v.s[215]++;
    if (((cov_ofivefy5v.b[30][0]++, item.CODE_CLCAFF) || (cov_ofivefy5v.b[30][1]++, '')).length == $scope.editParam.value) {
      cov_ofivefy5v.b[29][0]++;
      cov_ofivefy5v.s[216]++;

      return true;
    } else {
      cov_ofivefy5v.b[29][1]++;
    }
    cov_ofivefy5v.s[217]++;
    return false;
  };
  cov_ofivefy5v.s[218]++;
  $scope.validateItem = function (right_) {
    cov_ofivefy5v.f[47]++;
    cov_ofivefy5v.s[219]++;

    $scope.validationError = false;
    var p = (cov_ofivefy5v.s[220]++, {
      id: $scope.planItem.id,
      POIDS_CL: $scope.planItem.POIDS_CL,
      LIBELLE_L_CL: $scope.planItem.LIBELLE_L_CL,
      LIBELLE_C_CL: $scope.planItem.LIBELLE_C_CL,
      LIBELLE_L_CL_EN: $scope.planItem.LIBELLE_L_CL_EN,
      LIBELLE_C_CL_EN: $scope.planItem.LIBELLE_C_CL_EN,
      CODE_CLC: $filter('uppercase')($scope.planItem.id ? (cov_ofivefy5v.b[31][0]++, $scope.planItem.CODE_CLC) : (cov_ofivefy5v.b[31][1]++, $scope.planItem.FIXE + $scope.planItem.CODE_CLCAFF)),
      CODE_CLP: $scope.planItem.CODE_CLP,
      CODE_NCL: $scope.planItem.CODE_NCL,
      CODE_CLCAFF: $filter('uppercase')($scope.planItem.CODE_CLCAFF),
      leaf: $scope.planItem.leaf,
      expanded: false
    });
    cov_ofivefy5v.s[221]++;
    $scope.errorPL = false;
    // find doublons
    cov_ofivefy5v.s[222]++;
    if (p.id == 0) {
      cov_ofivefy5v.b[32][0]++;
      cov_ofivefy5v.s[223]++;

      if ($filter('filter')($scope.listData_pl_bksb, { CODE_CLC: p.CODE_CLC }, true)[0]) {
        cov_ofivefy5v.b[33][0]++;
        cov_ofivefy5v.s[224]++;

        $scope.errorPL = true;
        cov_ofivefy5v.s[225]++;
        return;
      } else {
        cov_ofivefy5v.b[33][1]++;
      }
      cov_ofivefy5v.s[226]++;
      p.ID_PROJET = $scope.planItem.NC.ID_PROJET;
    } else {
      cov_ofivefy5v.b[32][1]++;
    }

    cov_ofivefy5v.s[227]++;
    if ((cov_ofivefy5v.b[35][0]++, $scope.isPondActive == 1) && (cov_ofivefy5v.b[35][1]++, $scope.sumPoids >= 100)) {
      cov_ofivefy5v.b[34][0]++;
      cov_ofivefy5v.s[228]++;

      console.log($scope.sumPoids);
      cov_ofivefy5v.s[229]++;
      $scope.validationError = true;
      cov_ofivefy5v.s[230]++;
      return;
    } else {
      cov_ofivefy5v.b[34][1]++;
    }

    var oldid = (cov_ofivefy5v.s[231]++, p.id);
    cov_ofivefy5v.s[232]++;
    $CRUDService.save($scope.PATHPL, { action: 'miseAJour', valeur: $filter('json')(p) }, function (data) {
      cov_ofivefy5v.f[48]++;
      cov_ofivefy5v.s[233]++;

      /* if (data == -4) {
       } */
      if (data < 0) {
        cov_ofivefy5v.b[36][0]++;
        cov_ofivefy5v.s[234]++;

        return;
      } else {
        cov_ofivefy5v.b[36][1]++;
      }
      cov_ofivefy5v.s[235]++;
      if (oldid == 0) {
        cov_ofivefy5v.b[37][0]++;

        var parent = (cov_ofivefy5v.s[236]++, $filter('filter')($scope.listData_pl_bksb, { id: p.CODE_CLP }, true)[0]);
        cov_ofivefy5v.s[237]++;
        if (!parent) {
          cov_ofivefy5v.b[38][0]++;
          cov_ofivefy5v.s[238]++;

          p.id = parseInt(data);
          cov_ofivefy5v.s[239]++;
          p.view = right_.view;
          cov_ofivefy5v.s[240]++;
          p.update = right_.update;
          cov_ofivefy5v.s[241]++;
          p.delete = right_.delete;
          cov_ofivefy5v.s[242]++;
          $deltaPlanLogique.addData(p, $scope.planItem.NC.Niveau_CL, $scope.planItem.id != 0);
        } else {
            cov_ofivefy5v.b[38][1]++;
            cov_ofivefy5v.s[243]++;
            if (parent.isLoaded == true) {
              cov_ofivefy5v.b[39][0]++;
              cov_ofivefy5v.s[244]++;
              // Ajouter à la suite
              p.id = parseInt(data);
              cov_ofivefy5v.s[245]++;
              p.view = right_.view;
              cov_ofivefy5v.s[246]++;
              p.update = right_.update;
              cov_ofivefy5v.s[247]++;
              p.delete = right_.delete;
              cov_ofivefy5v.s[248]++;
              $deltaPlanLogique.addData(p, $scope.planItem.NC.Niveau_CL);
            } else {
              cov_ofivefy5v.b[39][1]++;
              cov_ofivefy5v.s[249]++;

              $scope.loadingPLData_bksb(parent);
            }
          }
      } else {
        cov_ofivefy5v.b[37][1]++;
        cov_ofivefy5v.s[250]++;

        p.view = right_.view;
        cov_ofivefy5v.s[251]++;
        p.update = right_.update;
        cov_ofivefy5v.s[252]++;
        p.delete = right_.delete;
        cov_ofivefy5v.s[253]++;
        $deltaPlanLogique.addData(p, $scope.planItem.NC.Niveau_CL, $scope.planItem.id != 0);
      }
      cov_ofivefy5v.s[254]++;
      $scope.getPoidsList();
      cov_ofivefy5v.s[255]++;
      $scope.getProject;
      cov_ofivefy5v.s[256]++;
      $scope.closeModal();
    });
  };
}
function logical_levelCtrl($scope, $uibModalInstance, $filter, $CRUDService) {
  cov_ofivefy5v.f[49]++;
  cov_ofivefy5v.s[257]++;

  $scope.LogLevel = {
    closeModal: function closeModal() {
      cov_ofivefy5v.s[258]++;

      $uibModalInstance.dismiss('cancel');
    }
  };
}