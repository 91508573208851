'use strict';

var cov_107fc7ujmt = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/directives/dashboard-combine-old.js',
      hash = 'cc7c6bcb6be8196f8294abb22818404d48aaaa20',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/directives/dashboard-combine-old.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 461,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 460,
          column: 4
        }
      },
      '2': {
        start: {
          line: 15,
          column: 19
        },
        end: {
          line: 15,
          column: 28
        }
      },
      '3': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 16,
          column: 67
        }
      },
      '4': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 19,
          column: 8
        }
      },
      '5': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 18,
          column: 53
        }
      },
      '6': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 23,
          column: 7
        }
      },
      '7': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 22,
          column: 52
        }
      },
      '8': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 42,
          column: 8
        }
      },
      '9': {
        start: {
          line: 26,
          column: 8
        },
        end: {
          line: 28,
          column: 9
        }
      },
      '10': {
        start: {
          line: 27,
          column: 10
        },
        end: {
          line: 27,
          column: 22
        }
      },
      '11': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 31,
          column: 9
        }
      },
      '12': {
        start: {
          line: 30,
          column: 10
        },
        end: {
          line: 30,
          column: 23
        }
      },
      '13': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 34,
          column: 9
        }
      },
      '14': {
        start: {
          line: 33,
          column: 10
        },
        end: {
          line: 33,
          column: 22
        }
      },
      '15': {
        start: {
          line: 35,
          column: 21
        },
        end: {
          line: 35,
          column: 44
        }
      },
      '16': {
        start: {
          line: 36,
          column: 8
        },
        end: {
          line: 40,
          column: 9
        }
      },
      '17': {
        start: {
          line: 37,
          column: 10
        },
        end: {
          line: 39,
          column: 11
        }
      },
      '18': {
        start: {
          line: 38,
          column: 12
        },
        end: {
          line: 38,
          column: 33
        }
      },
      '19': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 41,
          column: 21
        }
      },
      '20': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 64,
          column: 8
        }
      },
      '21': {
        start: {
          line: 44,
          column: 8
        },
        end: {
          line: 63,
          column: 9
        }
      },
      '22': {
        start: {
          line: 46,
          column: 12
        },
        end: {
          line: 46,
          column: 24
        }
      },
      '23': {
        start: {
          line: 48,
          column: 12
        },
        end: {
          line: 48,
          column: 24
        }
      },
      '24': {
        start: {
          line: 50,
          column: 12
        },
        end: {
          line: 50,
          column: 24
        }
      },
      '25': {
        start: {
          line: 52,
          column: 12
        },
        end: {
          line: 52,
          column: 24
        }
      },
      '26': {
        start: {
          line: 54,
          column: 12
        },
        end: {
          line: 54,
          column: 24
        }
      },
      '27': {
        start: {
          line: 56,
          column: 12
        },
        end: {
          line: 56,
          column: 24
        }
      },
      '28': {
        start: {
          line: 58,
          column: 12
        },
        end: {
          line: 58,
          column: 24
        }
      },
      '29': {
        start: {
          line: 60,
          column: 12
        },
        end: {
          line: 60,
          column: 24
        }
      },
      '30': {
        start: {
          line: 62,
          column: 12
        },
        end: {
          line: 62,
          column: 24
        }
      },
      '31': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 457,
          column: 8
        }
      },
      '32': {
        start: {
          line: 67,
          column: 10
        },
        end: {
          line: 70,
          column: 11
        }
      },
      '33': {
        start: {
          line: 68,
          column: 12
        },
        end: {
          line: 68,
          column: 67
        }
      },
      '34': {
        start: {
          line: 69,
          column: 12
        },
        end: {
          line: 69,
          column: 19
        }
      },
      '35': {
        start: {
          line: 71,
          column: 10
        },
        end: {
          line: 71,
          column: 65
        }
      },
      '36': {
        start: {
          line: 74,
          column: 10
        },
        end: {
          line: 76,
          column: 11
        }
      },
      '37': {
        start: {
          line: 75,
          column: 12
        },
        end: {
          line: 75,
          column: 25
        }
      },
      '38': {
        start: {
          line: 77,
          column: 10
        },
        end: {
          line: 79,
          column: 11
        }
      },
      '39': {
        start: {
          line: 78,
          column: 12
        },
        end: {
          line: 78,
          column: 24
        }
      },
      '40': {
        start: {
          line: 81,
          column: 10
        },
        end: {
          line: 83,
          column: 11
        }
      },
      '41': {
        start: {
          line: 82,
          column: 12
        },
        end: {
          line: 82,
          column: 24
        }
      },
      '42': {
        start: {
          line: 85,
          column: 10
        },
        end: {
          line: 87,
          column: 11
        }
      },
      '43': {
        start: {
          line: 86,
          column: 12
        },
        end: {
          line: 86,
          column: 24
        }
      },
      '44': {
        start: {
          line: 89,
          column: 10
        },
        end: {
          line: 89,
          column: 23
        }
      },
      '45': {
        start: {
          line: 92,
          column: 23
        },
        end: {
          line: 92,
          column: 63
        }
      },
      '46': {
        start: {
          line: 94,
          column: 10
        },
        end: {
          line: 116,
          column: 11
        }
      },
      '47': {
        start: {
          line: 95,
          column: 12
        },
        end: {
          line: 95,
          column: 32
        }
      },
      '48': {
        start: {
          line: 96,
          column: 12
        },
        end: {
          line: 98,
          column: 13
        }
      },
      '49': {
        start: {
          line: 97,
          column: 14
        },
        end: {
          line: 97,
          column: 21
        }
      },
      '50': {
        start: {
          line: 99,
          column: 12
        },
        end: {
          line: 101,
          column: 15
        }
      },
      '51': {
        start: {
          line: 100,
          column: 14
        },
        end: {
          line: 100,
          column: 40
        }
      },
      '52': {
        start: {
          line: 102,
          column: 17
        },
        end: {
          line: 116,
          column: 11
        }
      },
      '53': {
        start: {
          line: 103,
          column: 12
        },
        end: {
          line: 105,
          column: 13
        }
      },
      '54': {
        start: {
          line: 104,
          column: 14
        },
        end: {
          line: 104,
          column: 21
        }
      },
      '55': {
        start: {
          line: 106,
          column: 12
        },
        end: {
          line: 106,
          column: 32
        }
      },
      '56': {
        start: {
          line: 107,
          column: 12
        },
        end: {
          line: 109,
          column: 15
        }
      },
      '57': {
        start: {
          line: 108,
          column: 14
        },
        end: {
          line: 108,
          column: 40
        }
      },
      '58': {
        start: {
          line: 111,
          column: 12
        },
        end: {
          line: 113,
          column: 13
        }
      },
      '59': {
        start: {
          line: 112,
          column: 14
        },
        end: {
          line: 112,
          column: 21
        }
      },
      '60': {
        start: {
          line: 114,
          column: 12
        },
        end: {
          line: 114,
          column: 32
        }
      },
      '61': {
        start: {
          line: 115,
          column: 12
        },
        end: {
          line: 115,
          column: 28
        }
      },
      '62': {
        start: {
          line: 119,
          column: 10
        },
        end: {
          line: 148,
          column: 11
        }
      },
      '63': {
        start: {
          line: 120,
          column: 12
        },
        end: {
          line: 120,
          column: 106
        }
      },
      '64': {
        start: {
          line: 121,
          column: 12
        },
        end: {
          line: 128,
          column: 13
        }
      },
      '65': {
        start: {
          line: 122,
          column: 14
        },
        end: {
          line: 127,
          column: 16
        }
      },
      '66': {
        start: {
          line: 129,
          column: 17
        },
        end: {
          line: 148,
          column: 11
        }
      },
      '67': {
        start: {
          line: 130,
          column: 12
        },
        end: {
          line: 132,
          column: 13
        }
      },
      '68': {
        start: {
          line: 131,
          column: 14
        },
        end: {
          line: 131,
          column: 21
        }
      },
      '69': {
        start: {
          line: 133,
          column: 12
        },
        end: {
          line: 137,
          column: 13
        }
      },
      '70': {
        start: {
          line: 134,
          column: 14
        },
        end: {
          line: 136,
          column: 16
        }
      },
      '71': {
        start: {
          line: 139,
          column: 12
        },
        end: {
          line: 139,
          column: 110
        }
      },
      '72': {
        start: {
          line: 140,
          column: 12
        },
        end: {
          line: 147,
          column: 13
        }
      },
      '73': {
        start: {
          line: 141,
          column: 14
        },
        end: {
          line: 146,
          column: 16
        }
      },
      '74': {
        start: {
          line: 149,
          column: 10
        },
        end: {
          line: 149,
          column: 26
        }
      },
      '75': {
        start: {
          line: 152,
          column: 10
        },
        end: {
          line: 152,
          column: 27
        }
      },
      '76': {
        start: {
          line: 153,
          column: 10
        },
        end: {
          line: 156,
          column: 12
        }
      },
      '77': {
        start: {
          line: 159,
          column: 10
        },
        end: {
          line: 159,
          column: 27
        }
      },
      '78': {
        start: {
          line: 160,
          column: 10
        },
        end: {
          line: 163,
          column: 12
        }
      },
      '79': {
        start: {
          line: 166,
          column: 10
        },
        end: {
          line: 166,
          column: 27
        }
      },
      '80': {
        start: {
          line: 167,
          column: 10
        },
        end: {
          line: 170,
          column: 12
        }
      },
      '81': {
        start: {
          line: 174,
          column: 26
        },
        end: {
          line: 177,
          column: 11
        }
      },
      '82': {
        start: {
          line: 179,
          column: 10
        },
        end: {
          line: 179,
          column: 33
        }
      },
      '83': {
        start: {
          line: 180,
          column: 19
        },
        end: {
          line: 180,
          column: 21
        }
      },
      '84': {
        start: {
          line: 181,
          column: 24
        },
        end: {
          line: 181,
          column: 26
        }
      },
      '85': {
        start: {
          line: 182,
          column: 10
        },
        end: {
          line: 205,
          column: 11
        }
      },
      '86': {
        start: {
          line: 183,
          column: 12
        },
        end: {
          line: 183,
          column: 27
        }
      },
      '87': {
        start: {
          line: 184,
          column: 12
        },
        end: {
          line: 184,
          column: 39
        }
      },
      '88': {
        start: {
          line: 185,
          column: 12
        },
        end: {
          line: 185,
          column: 46
        }
      },
      '89': {
        start: {
          line: 187,
          column: 17
        },
        end: {
          line: 205,
          column: 11
        }
      },
      '90': {
        start: {
          line: 188,
          column: 12
        },
        end: {
          line: 188,
          column: 37
        }
      },
      '91': {
        start: {
          line: 189,
          column: 12
        },
        end: {
          line: 189,
          column: 46
        }
      },
      '92': {
        start: {
          line: 190,
          column: 12
        },
        end: {
          line: 190,
          column: 22
        }
      },
      '93': {
        start: {
          line: 192,
          column: 17
        },
        end: {
          line: 205,
          column: 11
        }
      },
      '94': {
        start: {
          line: 193,
          column: 12
        },
        end: {
          line: 193,
          column: 41
        }
      },
      '95': {
        start: {
          line: 194,
          column: 12
        },
        end: {
          line: 194,
          column: 22
        }
      },
      '96': {
        start: {
          line: 196,
          column: 17
        },
        end: {
          line: 205,
          column: 11
        }
      },
      '97': {
        start: {
          line: 197,
          column: 12
        },
        end: {
          line: 197,
          column: 46
        }
      },
      '98': {
        start: {
          line: 198,
          column: 12
        },
        end: {
          line: 198,
          column: 22
        }
      },
      '99': {
        start: {
          line: 201,
          column: 12
        },
        end: {
          line: 201,
          column: 42
        }
      },
      '100': {
        start: {
          line: 202,
          column: 12
        },
        end: {
          line: 202,
          column: 28
        }
      },
      '101': {
        start: {
          line: 203,
          column: 12
        },
        end: {
          line: 203,
          column: 46
        }
      },
      '102': {
        start: {
          line: 206,
          column: 10
        },
        end: {
          line: 354,
          column: 13
        }
      },
      '103': {
        start: {
          line: 207,
          column: 12
        },
        end: {
          line: 214,
          column: 13
        }
      },
      '104': {
        start: {
          line: 208,
          column: 14
        },
        end: {
          line: 210,
          column: 15
        }
      },
      '105': {
        start: {
          line: 209,
          column: 16
        },
        end: {
          line: 209,
          column: 23
        }
      },
      '106': {
        start: {
          line: 211,
          column: 14
        },
        end: {
          line: 213,
          column: 15
        }
      },
      '107': {
        start: {
          line: 212,
          column: 16
        },
        end: {
          line: 212,
          column: 23
        }
      },
      '108': {
        start: {
          line: 215,
          column: 12
        },
        end: {
          line: 215,
          column: 37
        }
      },
      '109': {
        start: {
          line: 216,
          column: 27
        },
        end: {
          line: 266,
          column: 13
        }
      },
      '110': {
        start: {
          line: 263,
          column: 16
        },
        end: {
          line: 263,
          column: 56
        }
      },
      '111': {
        start: {
          line: 267,
          column: 12
        },
        end: {
          line: 282,
          column: 13
        }
      },
      '112': {
        start: {
          line: 268,
          column: 24
        },
        end: {
          line: 280,
          column: 15
        }
      },
      '113': {
        start: {
          line: 281,
          column: 14
        },
        end: {
          line: 281,
          column: 36
        }
      },
      '114': {
        start: {
          line: 314,
          column: 12
        },
        end: {
          line: 335,
          column: 13
        }
      },
      '115': {
        start: {
          line: 315,
          column: 14
        },
        end: {
          line: 321,
          column: 17
        }
      },
      '116': {
        start: {
          line: 316,
          column: 16
        },
        end: {
          line: 320,
          column: 18
        }
      },
      '117': {
        start: {
          line: 323,
          column: 29
        },
        end: {
          line: 325,
          column: 19
        }
      },
      '118': {
        start: {
          line: 324,
          column: 16
        },
        end: {
          line: 324,
          column: 82
        }
      },
      '119': {
        start: {
          line: 326,
          column: 14
        },
        end: {
          line: 334,
          column: 15
        }
      },
      '120': {
        start: {
          line: 327,
          column: 16
        },
        end: {
          line: 333,
          column: 19
        }
      },
      '121': {
        start: {
          line: 328,
          column: 18
        },
        end: {
          line: 332,
          column: 20
        }
      },
      '122': {
        start: {
          line: 336,
          column: 27
        },
        end: {
          line: 336,
          column: 71
        }
      },
      '123': {
        start: {
          line: 337,
          column: 12
        },
        end: {
          line: 352,
          column: 13
        }
      },
      '124': {
        start: {
          line: 338,
          column: 14
        },
        end: {
          line: 338,
          column: 59
        }
      },
      '125': {
        start: {
          line: 344,
          column: 29
        },
        end: {
          line: 344,
          column: 147
        }
      },
      '126': {
        start: {
          line: 345,
          column: 14
        },
        end: {
          line: 351,
          column: 15
        }
      },
      '127': {
        start: {
          line: 346,
          column: 36
        },
        end: {
          line: 346,
          column: 66
        }
      },
      '128': {
        start: {
          line: 347,
          column: 16
        },
        end: {
          line: 350,
          column: 19
        }
      },
      '129': {
        start: {
          line: 353,
          column: 12
        },
        end: {
          line: 353,
          column: 113
        }
      },
      '130': {
        start: {
          line: 357,
          column: 24
        },
        end: {
          line: 357,
          column: 172
        }
      },
      '131': {
        start: {
          line: 358,
          column: 10
        },
        end: {
          line: 358,
          column: 51
        }
      },
      '132': {
        start: {
          line: 359,
          column: 10
        },
        end: {
          line: 359,
          column: 27
        }
      },
      '133': {
        start: {
          line: 360,
          column: 10
        },
        end: {
          line: 364,
          column: 11
        }
      },
      '134': {
        start: {
          line: 361,
          column: 12
        },
        end: {
          line: 361,
          column: 25
        }
      },
      '135': {
        start: {
          line: 362,
          column: 12
        },
        end: {
          line: 362,
          column: 25
        }
      },
      '136': {
        start: {
          line: 363,
          column: 12
        },
        end: {
          line: 363,
          column: 25
        }
      },
      '137': {
        start: {
          line: 365,
          column: 10
        },
        end: {
          line: 419,
          column: 12
        }
      },
      '138': {
        start: {
          line: 371,
          column: 36
        },
        end: {
          line: 371,
          column: 107
        }
      },
      '139': {
        start: {
          line: 372,
          column: 20
        },
        end: {
          line: 374,
          column: 21
        }
      },
      '140': {
        start: {
          line: 373,
          column: 22
        },
        end: {
          line: 373,
          column: 34
        }
      },
      '141': {
        start: {
          line: 375,
          column: 37
        },
        end: {
          line: 375,
          column: 63
        }
      },
      '142': {
        start: {
          line: 376,
          column: 20
        },
        end: {
          line: 378,
          column: 21
        }
      },
      '143': {
        start: {
          line: 377,
          column: 22
        },
        end: {
          line: 377,
          column: 40
        }
      },
      '144': {
        start: {
          line: 379,
          column: 20
        },
        end: {
          line: 379,
          column: 38
        }
      },
      '145': {
        start: {
          line: 388,
          column: 39
        },
        end: {
          line: 388,
          column: 63
        }
      },
      '146': {
        start: {
          line: 389,
          column: 22
        },
        end: {
          line: 391,
          column: 23
        }
      },
      '147': {
        start: {
          line: 390,
          column: 24
        },
        end: {
          line: 390,
          column: 42
        }
      },
      '148': {
        start: {
          line: 392,
          column: 22
        },
        end: {
          line: 392,
          column: 40
        }
      },
      '149': {
        start: {
          line: 403,
          column: 16
        },
        end: {
          line: 408,
          column: 17
        }
      },
      '150': {
        start: {
          line: 404,
          column: 18
        },
        end: {
          line: 407,
          column: 19
        }
      },
      '151': {
        start: {
          line: 405,
          column: 20
        },
        end: {
          line: 405,
          column: 62
        }
      },
      '152': {
        start: {
          line: 406,
          column: 20
        },
        end: {
          line: 406,
          column: 27
        }
      },
      '153': {
        start: {
          line: 420,
          column: 29
        },
        end: {
          line: 420,
          column: 31
        }
      },
      '154': {
        start: {
          line: 421,
          column: 10
        },
        end: {
          line: 431,
          column: 11
        }
      },
      '155': {
        start: {
          line: 422,
          column: 12
        },
        end: {
          line: 422,
          column: 33
        }
      },
      '156': {
        start: {
          line: 423,
          column: 12
        },
        end: {
          line: 423,
          column: 54
        }
      },
      '157': {
        start: {
          line: 424,
          column: 12
        },
        end: {
          line: 428,
          column: 15
        }
      },
      '158': {
        start: {
          line: 429,
          column: 12
        },
        end: {
          line: 429,
          column: 99
        }
      },
      '159': {
        start: {
          line: 430,
          column: 12
        },
        end: {
          line: 430,
          column: 73
        }
      },
      '160': {
        start: {
          line: 432,
          column: 10
        },
        end: {
          line: 432,
          column: 34
        }
      },
      '161': {
        start: {
          line: 433,
          column: 10
        },
        end: {
          line: 438,
          column: 11
        }
      },
      '162': {
        start: {
          line: 434,
          column: 12
        },
        end: {
          line: 437,
          column: 15
        }
      },
      '163': {
        start: {
          line: 435,
          column: 14
        },
        end: {
          line: 435,
          column: 45
        }
      },
      '164': {
        start: {
          line: 436,
          column: 14
        },
        end: {
          line: 436,
          column: 47
        }
      },
      '165': {
        start: {
          line: 441,
          column: 10
        },
        end: {
          line: 441,
          column: 27
        }
      },
      '166': {
        start: {
          line: 442,
          column: 10
        },
        end: {
          line: 454,
          column: 13
        }
      },
      '167': {
        start: {
          line: 455,
          column: 10
        },
        end: {
          line: 455,
          column: 26
        }
      },
      '168': {
        start: {
          line: 458,
          column: 6
        },
        end: {
          line: 458,
          column: 28
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 55
          },
          end: {
            line: 2,
            column: 56
          }
        },
        loc: {
          start: {
            line: 2,
            column: 61
          },
          end: {
            line: 461,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 17,
            column: 36
          },
          end: {
            line: 17,
            column: 37
          }
        },
        loc: {
          start: {
            line: 17,
            column: 52
          },
          end: {
            line: 19,
            column: 7
          }
        },
        line: 17
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 25,
            column: 30
          },
          end: {
            line: 25,
            column: 31
          }
        },
        loc: {
          start: {
            line: 25,
            column: 60
          },
          end: {
            line: 42,
            column: 7
          }
        },
        line: 25
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 43,
            column: 31
          },
          end: {
            line: 43,
            column: 32
          }
        },
        loc: {
          start: {
            line: 43,
            column: 48
          },
          end: {
            line: 64,
            column: 7
          }
        },
        line: 43
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 99,
            column: 42
          },
          end: {
            line: 99,
            column: 43
          }
        },
        loc: {
          start: {
            line: 99,
            column: 48
          },
          end: {
            line: 101,
            column: 13
          }
        },
        line: 99
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 107,
            column: 44
          },
          end: {
            line: 107,
            column: 45
          }
        },
        loc: {
          start: {
            line: 107,
            column: 50
          },
          end: {
            line: 109,
            column: 13
          }
        },
        line: 107
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 206,
            column: 58
          },
          end: {
            line: 206,
            column: 59
          }
        },
        loc: {
          start: {
            line: 206,
            column: 67
          },
          end: {
            line: 354,
            column: 11
          }
        },
        line: 206
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 315,
            column: 32
          },
          end: {
            line: 315,
            column: 33
          }
        },
        loc: {
          start: {
            line: 315,
            column: 42
          },
          end: {
            line: 321,
            column: 15
          }
        },
        line: 315
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 323,
            column: 42
          },
          end: {
            line: 323,
            column: 43
          }
        },
        loc: {
          start: {
            line: 323,
            column: 50
          },
          end: {
            line: 325,
            column: 15
          }
        },
        line: 323
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 327,
            column: 106
          },
          end: {
            line: 327,
            column: 107
          }
        },
        loc: {
          start: {
            line: 327,
            column: 114
          },
          end: {
            line: 333,
            column: 17
          }
        },
        line: 327
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 434,
            column: 63
          },
          end: {
            line: 434,
            column: 64
          }
        },
        loc: {
          start: {
            line: 434,
            column: 71
          },
          end: {
            line: 437,
            column: 13
          }
        },
        line: 434
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 21,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 21,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        }, {
          start: {
            line: 21,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        }],
        line: 21
      },
      '1': {
        loc: {
          start: {
            line: 26,
            column: 8
          },
          end: {
            line: 28,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 26,
            column: 8
          },
          end: {
            line: 28,
            column: 9
          }
        }, {
          start: {
            line: 26,
            column: 8
          },
          end: {
            line: 28,
            column: 9
          }
        }],
        line: 26
      },
      '2': {
        loc: {
          start: {
            line: 29,
            column: 8
          },
          end: {
            line: 31,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 29,
            column: 8
          },
          end: {
            line: 31,
            column: 9
          }
        }, {
          start: {
            line: 29,
            column: 8
          },
          end: {
            line: 31,
            column: 9
          }
        }],
        line: 29
      },
      '3': {
        loc: {
          start: {
            line: 32,
            column: 8
          },
          end: {
            line: 34,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 32,
            column: 8
          },
          end: {
            line: 34,
            column: 9
          }
        }, {
          start: {
            line: 32,
            column: 8
          },
          end: {
            line: 34,
            column: 9
          }
        }],
        line: 32
      },
      '4': {
        loc: {
          start: {
            line: 37,
            column: 10
          },
          end: {
            line: 39,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 37,
            column: 10
          },
          end: {
            line: 39,
            column: 11
          }
        }, {
          start: {
            line: 37,
            column: 10
          },
          end: {
            line: 39,
            column: 11
          }
        }],
        line: 37
      },
      '5': {
        loc: {
          start: {
            line: 44,
            column: 8
          },
          end: {
            line: 63,
            column: 9
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 45,
            column: 10
          },
          end: {
            line: 45,
            column: 17
          }
        }, {
          start: {
            line: 45,
            column: 18
          },
          end: {
            line: 46,
            column: 24
          }
        }, {
          start: {
            line: 47,
            column: 10
          },
          end: {
            line: 47,
            column: 17
          }
        }, {
          start: {
            line: 47,
            column: 18
          },
          end: {
            line: 48,
            column: 24
          }
        }, {
          start: {
            line: 49,
            column: 10
          },
          end: {
            line: 49,
            column: 17
          }
        }, {
          start: {
            line: 49,
            column: 18
          },
          end: {
            line: 50,
            column: 24
          }
        }, {
          start: {
            line: 51,
            column: 10
          },
          end: {
            line: 51,
            column: 17
          }
        }, {
          start: {
            line: 51,
            column: 18
          },
          end: {
            line: 52,
            column: 24
          }
        }, {
          start: {
            line: 53,
            column: 10
          },
          end: {
            line: 53,
            column: 17
          }
        }, {
          start: {
            line: 53,
            column: 18
          },
          end: {
            line: 54,
            column: 24
          }
        }, {
          start: {
            line: 55,
            column: 10
          },
          end: {
            line: 55,
            column: 17
          }
        }, {
          start: {
            line: 55,
            column: 18
          },
          end: {
            line: 56,
            column: 24
          }
        }, {
          start: {
            line: 57,
            column: 10
          },
          end: {
            line: 57,
            column: 17
          }
        }, {
          start: {
            line: 57,
            column: 18
          },
          end: {
            line: 58,
            column: 24
          }
        }, {
          start: {
            line: 59,
            column: 10
          },
          end: {
            line: 59,
            column: 17
          }
        }, {
          start: {
            line: 59,
            column: 18
          },
          end: {
            line: 60,
            column: 24
          }
        }, {
          start: {
            line: 61,
            column: 10
          },
          end: {
            line: 62,
            column: 24
          }
        }],
        line: 44
      },
      '6': {
        loc: {
          start: {
            line: 67,
            column: 10
          },
          end: {
            line: 70,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 10
          },
          end: {
            line: 70,
            column: 11
          }
        }, {
          start: {
            line: 67,
            column: 10
          },
          end: {
            line: 70,
            column: 11
          }
        }],
        line: 67
      },
      '7': {
        loc: {
          start: {
            line: 74,
            column: 10
          },
          end: {
            line: 76,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 74,
            column: 10
          },
          end: {
            line: 76,
            column: 11
          }
        }, {
          start: {
            line: 74,
            column: 10
          },
          end: {
            line: 76,
            column: 11
          }
        }],
        line: 74
      },
      '8': {
        loc: {
          start: {
            line: 77,
            column: 10
          },
          end: {
            line: 79,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 77,
            column: 10
          },
          end: {
            line: 79,
            column: 11
          }
        }, {
          start: {
            line: 77,
            column: 10
          },
          end: {
            line: 79,
            column: 11
          }
        }],
        line: 77
      },
      '9': {
        loc: {
          start: {
            line: 77,
            column: 14
          },
          end: {
            line: 77,
            column: 67
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 77,
            column: 14
          },
          end: {
            line: 77,
            column: 32
          }
        }, {
          start: {
            line: 77,
            column: 36
          },
          end: {
            line: 77,
            column: 67
          }
        }],
        line: 77
      },
      '10': {
        loc: {
          start: {
            line: 81,
            column: 10
          },
          end: {
            line: 83,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 81,
            column: 10
          },
          end: {
            line: 83,
            column: 11
          }
        }, {
          start: {
            line: 81,
            column: 10
          },
          end: {
            line: 83,
            column: 11
          }
        }],
        line: 81
      },
      '11': {
        loc: {
          start: {
            line: 81,
            column: 14
          },
          end: {
            line: 81,
            column: 87
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 81,
            column: 14
          },
          end: {
            line: 81,
            column: 47
          }
        }, {
          start: {
            line: 81,
            column: 51
          },
          end: {
            line: 81,
            column: 87
          }
        }],
        line: 81
      },
      '12': {
        loc: {
          start: {
            line: 85,
            column: 10
          },
          end: {
            line: 87,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 85,
            column: 10
          },
          end: {
            line: 87,
            column: 11
          }
        }, {
          start: {
            line: 85,
            column: 10
          },
          end: {
            line: 87,
            column: 11
          }
        }],
        line: 85
      },
      '13': {
        loc: {
          start: {
            line: 85,
            column: 14
          },
          end: {
            line: 85,
            column: 161
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 85,
            column: 14
          },
          end: {
            line: 85,
            column: 65
          }
        }, {
          start: {
            line: 85,
            column: 69
          },
          end: {
            line: 85,
            column: 124
          }
        }, {
          start: {
            line: 85,
            column: 128
          },
          end: {
            line: 85,
            column: 161
          }
        }],
        line: 85
      },
      '14': {
        loc: {
          start: {
            line: 94,
            column: 10
          },
          end: {
            line: 116,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 94,
            column: 10
          },
          end: {
            line: 116,
            column: 11
          }
        }, {
          start: {
            line: 94,
            column: 10
          },
          end: {
            line: 116,
            column: 11
          }
        }],
        line: 94
      },
      '15': {
        loc: {
          start: {
            line: 96,
            column: 12
          },
          end: {
            line: 98,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 96,
            column: 12
          },
          end: {
            line: 98,
            column: 13
          }
        }, {
          start: {
            line: 96,
            column: 12
          },
          end: {
            line: 98,
            column: 13
          }
        }],
        line: 96
      },
      '16': {
        loc: {
          start: {
            line: 102,
            column: 17
          },
          end: {
            line: 116,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 102,
            column: 17
          },
          end: {
            line: 116,
            column: 11
          }
        }, {
          start: {
            line: 102,
            column: 17
          },
          end: {
            line: 116,
            column: 11
          }
        }],
        line: 102
      },
      '17': {
        loc: {
          start: {
            line: 103,
            column: 12
          },
          end: {
            line: 105,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 103,
            column: 12
          },
          end: {
            line: 105,
            column: 13
          }
        }, {
          start: {
            line: 103,
            column: 12
          },
          end: {
            line: 105,
            column: 13
          }
        }],
        line: 103
      },
      '18': {
        loc: {
          start: {
            line: 111,
            column: 12
          },
          end: {
            line: 113,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 111,
            column: 12
          },
          end: {
            line: 113,
            column: 13
          }
        }, {
          start: {
            line: 111,
            column: 12
          },
          end: {
            line: 113,
            column: 13
          }
        }],
        line: 111
      },
      '19': {
        loc: {
          start: {
            line: 119,
            column: 10
          },
          end: {
            line: 148,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 119,
            column: 10
          },
          end: {
            line: 148,
            column: 11
          }
        }, {
          start: {
            line: 119,
            column: 10
          },
          end: {
            line: 148,
            column: 11
          }
        }],
        line: 119
      },
      '20': {
        loc: {
          start: {
            line: 121,
            column: 12
          },
          end: {
            line: 128,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 121,
            column: 12
          },
          end: {
            line: 128,
            column: 13
          }
        }, {
          start: {
            line: 121,
            column: 12
          },
          end: {
            line: 128,
            column: 13
          }
        }],
        line: 121
      },
      '21': {
        loc: {
          start: {
            line: 129,
            column: 17
          },
          end: {
            line: 148,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 129,
            column: 17
          },
          end: {
            line: 148,
            column: 11
          }
        }, {
          start: {
            line: 129,
            column: 17
          },
          end: {
            line: 148,
            column: 11
          }
        }],
        line: 129
      },
      '22': {
        loc: {
          start: {
            line: 130,
            column: 12
          },
          end: {
            line: 132,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 130,
            column: 12
          },
          end: {
            line: 132,
            column: 13
          }
        }, {
          start: {
            line: 130,
            column: 12
          },
          end: {
            line: 132,
            column: 13
          }
        }],
        line: 130
      },
      '23': {
        loc: {
          start: {
            line: 133,
            column: 12
          },
          end: {
            line: 137,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 133,
            column: 12
          },
          end: {
            line: 137,
            column: 13
          }
        }, {
          start: {
            line: 133,
            column: 12
          },
          end: {
            line: 137,
            column: 13
          }
        }],
        line: 133
      },
      '24': {
        loc: {
          start: {
            line: 140,
            column: 12
          },
          end: {
            line: 147,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 140,
            column: 12
          },
          end: {
            line: 147,
            column: 13
          }
        }, {
          start: {
            line: 140,
            column: 12
          },
          end: {
            line: 147,
            column: 13
          }
        }],
        line: 140
      },
      '25': {
        loc: {
          start: {
            line: 182,
            column: 10
          },
          end: {
            line: 205,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 182,
            column: 10
          },
          end: {
            line: 205,
            column: 11
          }
        }, {
          start: {
            line: 182,
            column: 10
          },
          end: {
            line: 205,
            column: 11
          }
        }],
        line: 182
      },
      '26': {
        loc: {
          start: {
            line: 187,
            column: 17
          },
          end: {
            line: 205,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 187,
            column: 17
          },
          end: {
            line: 205,
            column: 11
          }
        }, {
          start: {
            line: 187,
            column: 17
          },
          end: {
            line: 205,
            column: 11
          }
        }],
        line: 187
      },
      '27': {
        loc: {
          start: {
            line: 192,
            column: 17
          },
          end: {
            line: 205,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 192,
            column: 17
          },
          end: {
            line: 205,
            column: 11
          }
        }, {
          start: {
            line: 192,
            column: 17
          },
          end: {
            line: 205,
            column: 11
          }
        }],
        line: 192
      },
      '28': {
        loc: {
          start: {
            line: 196,
            column: 17
          },
          end: {
            line: 205,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 196,
            column: 17
          },
          end: {
            line: 205,
            column: 11
          }
        }, {
          start: {
            line: 196,
            column: 17
          },
          end: {
            line: 205,
            column: 11
          }
        }],
        line: 196
      },
      '29': {
        loc: {
          start: {
            line: 208,
            column: 14
          },
          end: {
            line: 210,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 208,
            column: 14
          },
          end: {
            line: 210,
            column: 15
          }
        }, {
          start: {
            line: 208,
            column: 14
          },
          end: {
            line: 210,
            column: 15
          }
        }],
        line: 208
      },
      '30': {
        loc: {
          start: {
            line: 211,
            column: 14
          },
          end: {
            line: 213,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 211,
            column: 14
          },
          end: {
            line: 213,
            column: 15
          }
        }, {
          start: {
            line: 211,
            column: 14
          },
          end: {
            line: 213,
            column: 15
          }
        }],
        line: 211
      },
      '31': {
        loc: {
          start: {
            line: 236,
            column: 42
          },
          end: {
            line: 236,
            column: 109
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 236,
            column: 101
          },
          end: {
            line: 236,
            column: 104
          }
        }, {
          start: {
            line: 236,
            column: 107
          },
          end: {
            line: 236,
            column: 109
          }
        }],
        line: 236
      },
      '32': {
        loc: {
          start: {
            line: 236,
            column: 43
          },
          end: {
            line: 236,
            column: 97
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 236,
            column: 43
          },
          end: {
            line: 236,
            column: 68
          }
        }, {
          start: {
            line: 236,
            column: 72
          },
          end: {
            line: 236,
            column: 97
          }
        }],
        line: 236
      },
      '33': {
        loc: {
          start: {
            line: 255,
            column: 21
          },
          end: {
            line: 255,
            column: 75
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 255,
            column: 50
          },
          end: {
            line: 255,
            column: 70
          }
        }, {
          start: {
            line: 255,
            column: 73
          },
          end: {
            line: 255,
            column: 75
          }
        }],
        line: 255
      },
      '34': {
        loc: {
          start: {
            line: 269,
            column: 22
          },
          end: {
            line: 269,
            column: 81
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 269,
            column: 41
          },
          end: {
            line: 269,
            column: 69
          }
        }, {
          start: {
            line: 269,
            column: 73
          },
          end: {
            line: 269,
            column: 81
          }
        }],
        line: 269
      },
      '35': {
        loc: {
          start: {
            line: 269,
            column: 41
          },
          end: {
            line: 269,
            column: 69
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 269,
            column: 54
          },
          end: {
            line: 269,
            column: 60
          }
        }, {
          start: {
            line: 269,
            column: 63
          },
          end: {
            line: 269,
            column: 69
          }
        }],
        line: 269
      },
      '36': {
        loc: {
          start: {
            line: 314,
            column: 12
          },
          end: {
            line: 335,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 314,
            column: 12
          },
          end: {
            line: 335,
            column: 13
          }
        }, {
          start: {
            line: 314,
            column: 12
          },
          end: {
            line: 335,
            column: 13
          }
        }],
        line: 314
      },
      '37': {
        loc: {
          start: {
            line: 324,
            column: 45
          },
          end: {
            line: 324,
            column: 66
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 324,
            column: 45
          },
          end: {
            line: 324,
            column: 60
          }
        }, {
          start: {
            line: 324,
            column: 64
          },
          end: {
            line: 324,
            column: 66
          }
        }],
        line: 324
      },
      '38': {
        loc: {
          start: {
            line: 326,
            column: 14
          },
          end: {
            line: 334,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 326,
            column: 14
          },
          end: {
            line: 334,
            column: 15
          }
        }, {
          start: {
            line: 326,
            column: 14
          },
          end: {
            line: 334,
            column: 15
          }
        }],
        line: 326
      },
      '39': {
        loc: {
          start: {
            line: 331,
            column: 41
          },
          end: {
            line: 331,
            column: 106
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 331,
            column: 72
          },
          end: {
            line: 331,
            column: 74
          }
        }, {
          start: {
            line: 331,
            column: 78
          },
          end: {
            line: 331,
            column: 105
          }
        }],
        line: 331
      },
      '40': {
        loc: {
          start: {
            line: 344,
            column: 29
          },
          end: {
            line: 344,
            column: 147
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 344,
            column: 48
          },
          end: {
            line: 344,
            column: 64
          }
        }, {
          start: {
            line: 344,
            column: 68
          },
          end: {
            line: 344,
            column: 146
          }
        }],
        line: 344
      },
      '41': {
        loc: {
          start: {
            line: 344,
            column: 68
          },
          end: {
            line: 344,
            column: 146
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 344,
            column: 82
          },
          end: {
            line: 344,
            column: 130
          }
        }, {
          start: {
            line: 344,
            column: 134
          },
          end: {
            line: 344,
            column: 146
          }
        }],
        line: 344
      },
      '42': {
        loc: {
          start: {
            line: 344,
            column: 82
          },
          end: {
            line: 344,
            column: 130
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 344,
            column: 82
          },
          end: {
            line: 344,
            column: 92
          }
        }, {
          start: {
            line: 344,
            column: 96
          },
          end: {
            line: 344,
            column: 112
          }
        }, {
          start: {
            line: 344,
            column: 116
          },
          end: {
            line: 344,
            column: 130
          }
        }],
        line: 344
      },
      '43': {
        loc: {
          start: {
            line: 346,
            column: 36
          },
          end: {
            line: 346,
            column: 66
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 346,
            column: 36
          },
          end: {
            line: 346,
            column: 60
          }
        }, {
          start: {
            line: 346,
            column: 64
          },
          end: {
            line: 346,
            column: 66
          }
        }],
        line: 346
      },
      '44': {
        loc: {
          start: {
            line: 349,
            column: 21
          },
          end: {
            line: 349,
            column: 48
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 349,
            column: 21
          },
          end: {
            line: 349,
            column: 40
          }
        }, {
          start: {
            line: 349,
            column: 44
          },
          end: {
            line: 349,
            column: 48
          }
        }],
        line: 349
      },
      '45': {
        loc: {
          start: {
            line: 357,
            column: 24
          },
          end: {
            line: 357,
            column: 172
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 357,
            column: 50
          },
          end: {
            line: 357,
            column: 79
          }
        }, {
          start: {
            line: 357,
            column: 83
          },
          end: {
            line: 357,
            column: 171
          }
        }],
        line: 357
      },
      '46': {
        loc: {
          start: {
            line: 357,
            column: 83
          },
          end: {
            line: 357,
            column: 171
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 357,
            column: 109
          },
          end: {
            line: 357,
            column: 134
          }
        }, {
          start: {
            line: 357,
            column: 137
          },
          end: {
            line: 357,
            column: 171
          }
        }],
        line: 357
      },
      '47': {
        loc: {
          start: {
            line: 360,
            column: 10
          },
          end: {
            line: 364,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 360,
            column: 10
          },
          end: {
            line: 364,
            column: 11
          }
        }, {
          start: {
            line: 360,
            column: 10
          },
          end: {
            line: 364,
            column: 11
          }
        }],
        line: 360
      },
      '48': {
        loc: {
          start: {
            line: 360,
            column: 14
          },
          end: {
            line: 360,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 360,
            column: 14
          },
          end: {
            line: 360,
            column: 37
          }
        }, {
          start: {
            line: 360,
            column: 41
          },
          end: {
            line: 360,
            column: 64
          }
        }],
        line: 360
      },
      '49': {
        loc: {
          start: {
            line: 371,
            column: 36
          },
          end: {
            line: 371,
            column: 107
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 371,
            column: 36
          },
          end: {
            line: 371,
            column: 99
          }
        }, {
          start: {
            line: 371,
            column: 103
          },
          end: {
            line: 371,
            column: 107
          }
        }],
        line: 371
      },
      '50': {
        loc: {
          start: {
            line: 372,
            column: 20
          },
          end: {
            line: 374,
            column: 21
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 372,
            column: 20
          },
          end: {
            line: 374,
            column: 21
          }
        }, {
          start: {
            line: 372,
            column: 20
          },
          end: {
            line: 374,
            column: 21
          }
        }],
        line: 372
      },
      '51': {
        loc: {
          start: {
            line: 376,
            column: 20
          },
          end: {
            line: 378,
            column: 21
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 376,
            column: 20
          },
          end: {
            line: 378,
            column: 21
          }
        }, {
          start: {
            line: 376,
            column: 20
          },
          end: {
            line: 378,
            column: 21
          }
        }],
        line: 376
      },
      '52': {
        loc: {
          start: {
            line: 376,
            column: 24
          },
          end: {
            line: 376,
            column: 78
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 376,
            column: 24
          },
          end: {
            line: 376,
            column: 49
          }
        }, {
          start: {
            line: 376,
            column: 53
          },
          end: {
            line: 376,
            column: 78
          }
        }],
        line: 376
      },
      '53': {
        loc: {
          start: {
            line: 389,
            column: 22
          },
          end: {
            line: 391,
            column: 23
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 389,
            column: 22
          },
          end: {
            line: 391,
            column: 23
          }
        }, {
          start: {
            line: 389,
            column: 22
          },
          end: {
            line: 391,
            column: 23
          }
        }],
        line: 389
      },
      '54': {
        loc: {
          start: {
            line: 389,
            column: 26
          },
          end: {
            line: 389,
            column: 80
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 389,
            column: 26
          },
          end: {
            line: 389,
            column: 51
          }
        }, {
          start: {
            line: 389,
            column: 55
          },
          end: {
            line: 389,
            column: 80
          }
        }],
        line: 389
      },
      '55': {
        loc: {
          start: {
            line: 404,
            column: 18
          },
          end: {
            line: 407,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 404,
            column: 18
          },
          end: {
            line: 407,
            column: 19
          }
        }, {
          start: {
            line: 404,
            column: 18
          },
          end: {
            line: 407,
            column: 19
          }
        }],
        line: 404
      },
      '56': {
        loc: {
          start: {
            line: 426,
            column: 27
          },
          end: {
            line: 426,
            column: 69
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 426,
            column: 64
          },
          end: {
            line: 426,
            column: 65
          }
        }, {
          start: {
            line: 426,
            column: 68
          },
          end: {
            line: 426,
            column: 69
          }
        }],
        line: 426
      },
      '57': {
        loc: {
          start: {
            line: 427,
            column: 20
          },
          end: {
            line: 427,
            column: 71
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 427,
            column: 57
          },
          end: {
            line: 427,
            column: 63
          }
        }, {
          start: {
            line: 427,
            column: 66
          },
          end: {
            line: 427,
            column: 71
          }
        }],
        line: 427
      },
      '58': {
        loc: {
          start: {
            line: 442,
            column: 25
          },
          end: {
            line: 454,
            column: 12
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 442,
            column: 51
          },
          end: {
            line: 447,
            column: 11
          }
        }, {
          start: {
            line: 447,
            column: 15
          },
          end: {
            line: 454,
            column: 11
          }
        }],
        line: 442
      },
      '59': {
        loc: {
          start: {
            line: 447,
            column: 15
          },
          end: {
            line: 454,
            column: 11
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 447,
            column: 41
          },
          end: {
            line: 452,
            column: 11
          }
        }, {
          start: {
            line: 452,
            column: 14
          },
          end: {
            line: 454,
            column: 11
          }
        }],
        line: 447
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0],
      '54': [0, 0],
      '55': [0, 0],
      '56': [0, 0],
      '57': [0, 0],
      '58': [0, 0],
      '59': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_107fc7ujmt.s[0]++;

angular.module('app').directive('dashboardCombineOld', function () {
  cov_107fc7ujmt.f[0]++;
  cov_107fc7ujmt.s[1]++;

  return {
    restrict: 'E',
    scope: {
      idcomponent: '@',
      data: '=',
      analyse: '=',
      titleDashboard: '@',
      height: '@'
    },
    templateUrl: 'app/views/common/dashboard-combine.html',

    controller: function controller($scope, $deltadate, $rootScope, $translate, $filter, $CRUDService) {
      var PATH = (cov_107fc7ujmt.s[2]++, 'Analyse');
      cov_107fc7ujmt.s[3]++;
      $scope.ISINCLUDEDRAPPORT = $rootScope.getISINCLUDEDRAPPORT();
      cov_107fc7ujmt.s[4]++;
      $scope.getCadreLogiqueLabel = function (item) {
        cov_107fc7ujmt.f[1]++;
        cov_107fc7ujmt.s[5]++;

        return $rootScope.getCadreLogiqueLabel(item);
      };

      cov_107fc7ujmt.s[6]++;
      if (!$scope.idcomponent) {
        cov_107fc7ujmt.b[0][0]++;
        cov_107fc7ujmt.s[7]++;

        $scope.idcomponent = new Date().getTime();
      } else {
        cov_107fc7ujmt.b[0][1]++;
      }

      cov_107fc7ujmt.s[8]++;
      $scope.filteData_date = function (item, index, items) {
        cov_107fc7ujmt.f[2]++;
        cov_107fc7ujmt.s[9]++;

        if (item.id == '0_0_0') {
          cov_107fc7ujmt.b[1][0]++;
          cov_107fc7ujmt.s[10]++;

          return true;
        } else {
          cov_107fc7ujmt.b[1][1]++;
        }
        cov_107fc7ujmt.s[11]++;
        if (!items[0].open) {
          cov_107fc7ujmt.b[2][0]++;
          cov_107fc7ujmt.s[12]++;

          return false;
        } else {
          cov_107fc7ujmt.b[2][1]++;
        }
        cov_107fc7ujmt.s[13]++;
        if (item.label.length == 4) {
          cov_107fc7ujmt.b[3][0]++;
          cov_107fc7ujmt.s[14]++;

          return true;
        } else {
          cov_107fc7ujmt.b[3][1]++;
        }
        var year = (cov_107fc7ujmt.s[15]++, item.id.substring(0, 4));
        cov_107fc7ujmt.s[16]++;
        for (var i = index - 1; i >= 0; i--) {
          cov_107fc7ujmt.s[17]++;

          if (year == items[i].label) {
            cov_107fc7ujmt.b[4][0]++;
            cov_107fc7ujmt.s[18]++;

            return items[i].open;
          } else {
            cov_107fc7ujmt.b[4][1]++;
          }
        }
        cov_107fc7ujmt.s[19]++;
        return false;
      };
      cov_107fc7ujmt.s[20]++;
      $scope.getCorrectIndex = function (index) {
        cov_107fc7ujmt.f[3]++;
        cov_107fc7ujmt.s[21]++;

        switch (index) {
          case 8:
            cov_107fc7ujmt.b[5][0]++;
          case '8':
            cov_107fc7ujmt.b[5][1]++;
            cov_107fc7ujmt.s[22]++;

            return 'BB';
          case 7:
            cov_107fc7ujmt.b[5][2]++;
          case '7':
            cov_107fc7ujmt.b[5][3]++;
            cov_107fc7ujmt.s[23]++;

            return 'CB';
          case 6:
            cov_107fc7ujmt.b[5][4]++;
          case '6':
            cov_107fc7ujmt.b[5][5]++;
            cov_107fc7ujmt.s[24]++;

            return 'EB';
          case 2:
            cov_107fc7ujmt.b[5][6]++;
          case '2':
            cov_107fc7ujmt.b[5][7]++;
            cov_107fc7ujmt.s[25]++;

            return 'EP';
          case 4:
            cov_107fc7ujmt.b[5][8]++;
          case '4':
            cov_107fc7ujmt.b[5][9]++;
            cov_107fc7ujmt.s[26]++;

            return 'PP';
          case 3:
            cov_107fc7ujmt.b[5][10]++;
          case '3':
            cov_107fc7ujmt.b[5][11]++;
            cov_107fc7ujmt.s[27]++;

            return 'RP';
          case 5:
            cov_107fc7ujmt.b[5][12]++;
          case '5':
            cov_107fc7ujmt.b[5][13]++;
            cov_107fc7ujmt.s[28]++;

            return 'TB';
          case 1:
            cov_107fc7ujmt.b[5][14]++;
          case '1':
            cov_107fc7ujmt.b[5][15]++;
            cov_107fc7ujmt.s[29]++;

            return 'TP';
          default:
            cov_107fc7ujmt.b[5][16]++;
            cov_107fc7ujmt.s[30]++;

            return 'TP';
        }
      };
      cov_107fc7ujmt.s[31]++;
      $scope.process = {
        export_data: function export_data(type) {
          cov_107fc7ujmt.s[32]++;

          if (type == 1) {
            cov_107fc7ujmt.b[6][0]++;
            cov_107fc7ujmt.s[33]++;

            $scope.chartDesign.export('PNG', $scope.analyse.TITLE);
            cov_107fc7ujmt.s[34]++;
            return;
          } else {
            cov_107fc7ujmt.b[6][1]++;
          }
          cov_107fc7ujmt.s[35]++;
          $scope.chartDesign.export('PDF', $scope.analyse.TITLE);
        },
        filter_date: function filter_date(item) {
          cov_107fc7ujmt.s[36]++;

          if ($scope.analyse.AXE != 2) {
            cov_107fc7ujmt.b[7][0]++;
            cov_107fc7ujmt.s[37]++;

            return false;
          } else {
            cov_107fc7ujmt.b[7][1]++;
          }
          cov_107fc7ujmt.s[38]++;
          if ((cov_107fc7ujmt.b[9][0]++, item.id == '0_0_0') && (cov_107fc7ujmt.b[9][1]++, $scope.process.itemCol.id == '')) {
            cov_107fc7ujmt.b[8][0]++;
            cov_107fc7ujmt.s[39]++;

            return true;
          } else {
            cov_107fc7ujmt.b[8][1]++;
          }

          cov_107fc7ujmt.s[40]++;
          if ((cov_107fc7ujmt.b[11][0]++, item.id.substring(4, 8) == '_1_1') && (cov_107fc7ujmt.b[11][1]++, $scope.process.itemCol.id == '0_0_0')) {
            cov_107fc7ujmt.b[10][0]++;
            cov_107fc7ujmt.s[41]++;

            return true;
          } else {
            cov_107fc7ujmt.b[10][1]++;
          }

          cov_107fc7ujmt.s[42]++;
          if ((cov_107fc7ujmt.b[13][0]++, $scope.process.itemCol.id.substring(4, 8) == '_1_1') && (cov_107fc7ujmt.b[13][1]++, item.id.substring(0, 4) == $scope.process.itemCol.label) && (cov_107fc7ujmt.b[13][2]++, item.id.substring(4, 8) != '_1_1')) {
            cov_107fc7ujmt.b[12][0]++;
            cov_107fc7ujmt.s[43]++;

            return true;
          } else {
            cov_107fc7ujmt.b[12][1]++;
          }

          cov_107fc7ujmt.s[44]++;
          return false;
        },
        deroule: function deroule(index) {
          var item = (cov_107fc7ujmt.s[45]++, $scope.process.grapheParams.items[index]);
          // this.itemCol = item;
          cov_107fc7ujmt.s[46]++;
          if ($scope.analyse.AXE == 3) {
            cov_107fc7ujmt.b[14][0]++;
            cov_107fc7ujmt.s[47]++;

            this.itemCol = item;
            cov_107fc7ujmt.s[48]++;
            if (item.leaf) {
              cov_107fc7ujmt.b[15][0]++;
              cov_107fc7ujmt.s[49]++;

              return;
            } else {
              cov_107fc7ujmt.b[15][1]++;
            }
            cov_107fc7ujmt.s[50]++;
            $scope.loadingData_bksb(item, function () {
              cov_107fc7ujmt.f[4]++;
              cov_107fc7ujmt.s[51]++;

              $scope.process.loadData();
            });
          } else {
              cov_107fc7ujmt.b[14][1]++;
              cov_107fc7ujmt.s[52]++;
              if ($scope.analyse.AXE == 1) {
                cov_107fc7ujmt.b[16][0]++;
                cov_107fc7ujmt.s[53]++;

                if (item.leaf) {
                  cov_107fc7ujmt.b[17][0]++;
                  cov_107fc7ujmt.s[54]++;

                  return;
                } else {
                  cov_107fc7ujmt.b[17][1]++;
                }
                cov_107fc7ujmt.s[55]++;
                this.itemCol = item;
                cov_107fc7ujmt.s[56]++;
                $scope.loadingPLData_bksb(item, function () {
                  cov_107fc7ujmt.f[5]++;
                  cov_107fc7ujmt.s[57]++;

                  $scope.process.loadData();
                });
              } else {
                cov_107fc7ujmt.b[16][1]++;
                cov_107fc7ujmt.s[58]++;

                if (item.leveld != 2) {
                  cov_107fc7ujmt.b[18][0]++;
                  cov_107fc7ujmt.s[59]++;

                  return;
                } else {
                  cov_107fc7ujmt.b[18][1]++;
                }
                cov_107fc7ujmt.s[60]++;
                this.itemCol = item;
                cov_107fc7ujmt.s[61]++;
                this.loadData();
              }
            }
        },
        enroule: function enroule() {
          cov_107fc7ujmt.s[62]++;

          if ($scope.analyse.AXE == 3) {
            cov_107fc7ujmt.b[19][0]++;
            cov_107fc7ujmt.s[63]++;

            this.itemCol = $filter('filter')($scope.listData_bksb, { id: this.itemCol.CODE_LP }, true)[0];
            cov_107fc7ujmt.s[64]++;
            if (!this.itemCol) {
              cov_107fc7ujmt.b[20][0]++;
              cov_107fc7ujmt.s[65]++;

              this.itemCol = {
                level: 0,
                CODE_LC: '',
                id: null,
                CODE_LP: -1
              };
            } else {
              cov_107fc7ujmt.b[20][1]++;
            }
          } else {
              cov_107fc7ujmt.b[19][1]++;
              cov_107fc7ujmt.s[66]++;
              if ($scope.analyse.AXE == 2) {
                cov_107fc7ujmt.b[21][0]++;
                cov_107fc7ujmt.s[67]++;

                if (this.itemCol.id == '0_0_0') {
                  cov_107fc7ujmt.b[22][0]++;
                  cov_107fc7ujmt.s[68]++;

                  return;
                } else {
                  cov_107fc7ujmt.b[22][1]++;
                }
                cov_107fc7ujmt.s[69]++;
                if (this.itemCol.id.substring(4, 8) == '_1_1') {
                  cov_107fc7ujmt.b[23][0]++;
                  cov_107fc7ujmt.s[70]++;

                  this.itemCol = {
                    id: '0_0_0'
                  };
                } else {
                  cov_107fc7ujmt.b[23][1]++;
                }
              } else {
                cov_107fc7ujmt.b[21][1]++;
                cov_107fc7ujmt.s[71]++;

                this.itemCol = $filter('filter')($scope.listData_pl_bksb, { id: this.itemCol.CODE_CLP }, true)[0];
                cov_107fc7ujmt.s[72]++;
                if (!this.itemCol) {
                  cov_107fc7ujmt.b[24][0]++;
                  cov_107fc7ujmt.s[73]++;

                  this.itemCol = {
                    level: 0,
                    CODE_CLC: '',
                    id: null,
                    CODE_CLP: -1
                  };
                } else {
                  cov_107fc7ujmt.b[24][1]++;
                }
              }
            }cov_107fc7ujmt.s[74]++;
          this.loadData();
        },
        __loadData_planlogique: function __loadData_planlogique() {
          cov_107fc7ujmt.s[75]++;

          $scope.data = {};
          cov_107fc7ujmt.s[76]++;
          return {
            a: this.itemCol.CODE_CLC,
            la: this.itemCol.level
          };
        },
        __loadData_plangeographique: function __loadData_plangeographique() {
          cov_107fc7ujmt.s[77]++;

          $scope.data = {};
          cov_107fc7ujmt.s[78]++;
          return {
            a: this.itemCol.CODE_LC,
            la: this.itemCol.level
          };
        },
        __loadData_periode: function __loadData_periode() {
          cov_107fc7ujmt.s[79]++;

          $scope.data = {};
          cov_107fc7ujmt.s[80]++;
          return {
            a: this.itemCol.id,
            la: 0
          };
        },
        __launchDrawing: function __launchDrawing() {
          // Get Concerne Data
          var params_ = (cov_107fc7ujmt.s[81]++, {
            data: { get: 'all', p_bksb_: $scope.analyse.ID_PROJET, simple: 1 },
            path: ''
          });

          cov_107fc7ujmt.s[82]++;
          $scope.amountTotal = 0;
          var id = (cov_107fc7ujmt.s[83]++, '');
          var items = (cov_107fc7ujmt.s[84]++, []);
          cov_107fc7ujmt.s[85]++;
          if ($scope.analyse.AXE == 3) {
            cov_107fc7ujmt.b[25][0]++;
            cov_107fc7ujmt.s[86]++;

            id = 'CODE_LC';
            cov_107fc7ujmt.s[87]++;
            params_.path = 'Localites';
            cov_107fc7ujmt.s[88]++;
            params_.data.id = this.itemCol.id;
            // items = $filter('filter')($scope.listData_bksb, {CODE_LP: this.itemCol.id}, true);
          } else {
              cov_107fc7ujmt.b[25][1]++;
              cov_107fc7ujmt.s[89]++;
              if ($scope.analyse.AXE == 2) {
                cov_107fc7ujmt.b[26][0]++;
                cov_107fc7ujmt.s[90]++;

                params_.path = 'Periode';
                cov_107fc7ujmt.s[91]++;
                params_.data.id = this.itemCol.id;
                cov_107fc7ujmt.s[92]++;
                id = 'id';
                // items = $scope.dataperiodeGlobal;
              } else {
                  cov_107fc7ujmt.b[26][1]++;
                  cov_107fc7ujmt.s[93]++;
                  if ($scope.analyse.AXE == 4) {
                    cov_107fc7ujmt.b[27][0]++;
                    cov_107fc7ujmt.s[94]++;

                    params_.path = 'Financement';
                    cov_107fc7ujmt.s[95]++;
                    id = 'id';
                    // items = $scope.listData_FinancialSource_bksb;
                  } else {
                      cov_107fc7ujmt.b[27][1]++;
                      cov_107fc7ujmt.s[96]++;
                      if ($scope.analyse.AXE == 5) {
                        cov_107fc7ujmt.b[28][0]++;
                        cov_107fc7ujmt.s[97]++;

                        params_.path = 'CategoriesBudget';
                        cov_107fc7ujmt.s[98]++;
                        id = 'id';
                        // items = $scope.listData_CategoriesBudget_bksb;
                      } else {
                        cov_107fc7ujmt.b[28][1]++;
                        cov_107fc7ujmt.s[99]++;

                        params_.path = 'CadreLogique';
                        cov_107fc7ujmt.s[100]++;
                        id = 'CODE_CLC';
                        cov_107fc7ujmt.s[101]++;
                        params_.data.id = this.itemCol.id;
                        // items = $filter('filter')($scope.listData_pl_bksb, {CODE_CLP: this.itemCol.id}, true);
                      }
                    }
                }
            }cov_107fc7ujmt.s[102]++;
          $CRUDService.getAll(params_.path, params_.data, function (items) {
            cov_107fc7ujmt.f[6]++;
            cov_107fc7ujmt.s[103]++;

            for (var i = $scope.analyse.SERIES.length - 1; i >= 0; i--) {
              cov_107fc7ujmt.s[104]++;

              if (angular.isUndefined($scope.data[i])) {
                cov_107fc7ujmt.b[29][0]++;
                cov_107fc7ujmt.s[105]++;

                return;
              } else {
                cov_107fc7ujmt.b[29][1]++;
              }
              cov_107fc7ujmt.s[106]++;
              if (angular.isUndefined($scope.data[i].index)) {
                cov_107fc7ujmt.b[30][0]++;
                cov_107fc7ujmt.s[107]++;

                return;
              } else {
                cov_107fc7ujmt.b[30][1]++;
              }
            }
            cov_107fc7ujmt.s[108]++;
            $scope.isloading = false;
            var params = (cov_107fc7ujmt.s[109]++, {
              // Initializing Primary X and Y Axis
              primaryXAxis: {
                // title: 'Years',
                interval: 1,
                labelIntersectAction: 'Rotate45',
                valueType: 'Category',
                majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
                majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
                lineStyle: { width: 0 }
              },
              primaryYAxis: {
                // title: 'Growth',
                /* minimum: -3,
                maximum: 3,
                interval: 1, */
                lineStyle: { width: 0 },
                majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
                minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
                labelFormat: '{value}' + ((cov_107fc7ujmt.b[32][0]++, $scope.analyse.FIELD == 1) || (cov_107fc7ujmt.b[32][1]++, $scope.analyse.FIELD == 5) ? (cov_107fc7ujmt.b[31][0]++, '%') : (cov_107fc7ujmt.b[31][1]++, ''))
              },
              chartArea: {
                border: {
                  width: 0
                }
              },
              // Initializing Chart Series
              series: [],
              textRender: function textRender(args, a, b) {

                /* if (!args.extraData) {
                  args.extraData = $filter('number')(args.text);
                }
                args.text = args.point.x + ' (' + args.extraData + (($scope.analyse.FIELD == 1 || $scope.analyse.FIELD == 5) ? '%' : '') + ')'; */
              },

              width: '100%',
              height: '90%',
              // Initializing Chart title
              title: $scope.titleDashboard == 1 ? (cov_107fc7ujmt.b[33][0]++, $scope.analyse.TITLE) : (cov_107fc7ujmt.b[33][1]++, ''),
              // crosshair: {enable: true, lineType: 'Vertical'},
              // Enable Legend
              legendSettings: {
                visible: true
              },
              // selectionMode: 'Series',
              pointClick: function pointClick(args) {
                cov_107fc7ujmt.s[110]++;

                $scope.process.deroule(args.pointIndex);
              },

              tooltip: { enable: true, shared: false }
            });
            cov_107fc7ujmt.s[111]++;
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = $scope.analyse.SERIES[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var serie = _step.value;

                var _ = (cov_107fc7ujmt.s[112]++, { // FILL
                  type: serie.TYPE == 1 ? (cov_107fc7ujmt.b[34][0]++, serie.FILL ? (cov_107fc7ujmt.b[35][0]++, 'Area') : (cov_107fc7ujmt.b[35][1]++, 'Line')) : (cov_107fc7ujmt.b[34][1]++, 'Column'),
                  opacity: 0.5,
                  dataSource: [],
                  fill: 'rgba(' + serie.COLOR + ',0.7)',
                  xName: 'x', yName: 'y', name: serie.TITLE,
                  width: 3,
                  marker: {
                    visible: serie.TYPE == 1 /* && !serie.FILL */
                    , width: 10,
                    height: 10
                  }
                });
                cov_107fc7ujmt.s[113]++;
                params.series.push(_);
              }
              // Search child
              // let id = '';
              /* let items = [];
               if ($scope.analyse.AXE == 3) {
                 id = 'CODE_LC';
                 items = $filter('filter')($scope.listData_bksb, {CODE_LP: this.itemCol.id}, true);
               } else if ($scope.analyse.AXE == 2) {
                 id = 'id';
                 const yearFinal = this.itemCol.id.substring(0, 4);
                 for (const item of $scope.dataperiodeGlobal) {
                   if (this.itemCol.id == '0_0_0') {
                     if (item.leveld == 2) {
                       items.push(item);
                     }
                     continue;
                   }
                   if (item.id.substring(0, 4) == yearFinal && item.leveld != 2) {
                     items.push(item);
                   }
                 }
               } else if ($scope.analyse.AXE == 4) {
                 id = 'id';
                 items = $scope.listData_FinancialSource_bksb;
               } else if ($scope.analyse.AXE == 5) {
                 id = 'id';
                 items = $scope.listData_CategoriesBudget_bksb;
               } else {
                 id = 'CODE_CLC';
                 items = $filter('filter')($scope.listData_pl_bksb, {CODE_CLP: this.itemCol.id}, true);
               } */
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            cov_107fc7ujmt.s[114]++;
            if (params_.data.id == '0_0_0') {
              cov_107fc7ujmt.b[36][0]++;
              cov_107fc7ujmt.s[115]++;

              items = items.map(function (period) {
                cov_107fc7ujmt.f[7]++;
                cov_107fc7ujmt.s[116]++;

                return {
                  leveld: 1,
                  id: period.ANNEE_PERIOD + '_1_1',
                  label: period.ANNEE_PERIOD
                };
              });
            } else {
              cov_107fc7ujmt.b[36][1]++;

              var myYear = (cov_107fc7ujmt.s[117]++, items.filter(function (item) {
                cov_107fc7ujmt.f[8]++;
                cov_107fc7ujmt.s[118]++;

                return item.ANNEE_PERIOD == ((cov_107fc7ujmt.b[37][0]++, params_.data.id) || (cov_107fc7ujmt.b[37][1]++, '')).split('_')[0];
              })[0]);
              cov_107fc7ujmt.s[119]++;
              if (myYear) {
                cov_107fc7ujmt.b[38][0]++;
                cov_107fc7ujmt.s[120]++;

                items = $deltadate.getYearPeriod(myYear.ANNEE_PERIOD, myYear.DECOUPAGE_PERIOD, false).map(function (item) {
                  cov_107fc7ujmt.f[9]++;
                  cov_107fc7ujmt.s[121]++;

                  return {
                    leveld: 2,
                    id: myYear.ANNEE_PERIOD + '_' + item.index + '_' + myYear.DECOUPAGE_PERIOD,
                    label: item.label + (myYear.DECOUPAGE_PERIOD == 1 ? (cov_107fc7ujmt.b[39][0]++, '') : (cov_107fc7ujmt.b[39][1]++, ' - ' + myYear.ANNEE_PERIOD))
                  };
                });
              } else {
                cov_107fc7ujmt.b[38][1]++;
              }
            }
            var index_ = (cov_107fc7ujmt.s[122]++, $scope.getCorrectIndex($scope.analyse.FIELD));
            cov_107fc7ujmt.s[123]++;
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = items[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var item = _step2.value;
                cov_107fc7ujmt.s[124]++;

                $scope.process.grapheParams.items.push(item);
                /* $scope.process.grapheParams.labels.push(
                                (id == 'CODE_CLC' ? item.CODE_CLCAFF : (id == 'id' ? (item.label || item.LIBELLE_FIN || item.LABEL_CAT) : item.CODE_LC))
                            ); */
                // const label = (id === 'CODE_CLC' ? item.CODE_CLCAFF + ' - ' + $rootScope.getCadreLogiqueLabel(item) : (id === 'id' ? (item.label || item.LIBELLE_FIN || item.LABEL_CAT) : item.CODE_LC + ' - ' + item.DESCRIPTION_L));

                var label = (cov_107fc7ujmt.s[125]++, id == 'CODE_CLC' ? (cov_107fc7ujmt.b[40][0]++, item.CODE_CLCAFF) : (cov_107fc7ujmt.b[40][1]++, id == 'id' ? (cov_107fc7ujmt.b[41][0]++, (cov_107fc7ujmt.b[42][0]++, item.label) || (cov_107fc7ujmt.b[42][1]++, item.LIBELLE_FIN) || (cov_107fc7ujmt.b[42][2]++, item.LABEL_CAT)) : (cov_107fc7ujmt.b[41][1]++, item.CODE_LC)));
                cov_107fc7ujmt.s[126]++;
                for (var _i = $scope.analyse.SERIES.length - 1; _i >= 0; _i--) {
                  var valueGraphe = (cov_107fc7ujmt.s[127]++, (cov_107fc7ujmt.b[43][0]++, $scope.data[_i][item[id]]) || (cov_107fc7ujmt.b[43][1]++, {}));
                  cov_107fc7ujmt.s[128]++;
                  params.series[_i].dataSource.push({
                    x: label,
                    y: (cov_107fc7ujmt.b[44][0]++, valueGraphe[index_]) || (cov_107fc7ujmt.b[44][1]++, null)
                  });
                }
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                  _iterator2.return();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }

            cov_107fc7ujmt.s[129]++;
            $scope.chartDesign = new ej.charts.Chart(params, '#dashboard_combine_graphe__' + $scope.idcomponent);
          });
        },
        loadData: function loadData() {
          var param = (cov_107fc7ujmt.s[130]++, $scope.analyse.AXE == 1 ? (cov_107fc7ujmt.b[45][0]++, this.__loadData_planlogique()) : (cov_107fc7ujmt.b[45][1]++, $scope.analyse.AXE == 2 ? (cov_107fc7ujmt.b[46][0]++, this.__loadData_periode()) : (cov_107fc7ujmt.b[46][1]++, this.__loadData_plangeographique())));
          cov_107fc7ujmt.s[131]++;
          param.p_bksb_ = $scope.analyse.ID_PROJET;
          cov_107fc7ujmt.s[132]++;
          $scope.data = [];
          cov_107fc7ujmt.s[133]++;
          if ((cov_107fc7ujmt.b[48][0]++, $scope.analyse.AXE == 4) || (cov_107fc7ujmt.b[48][1]++, $scope.analyse.AXE == 5)) {
            cov_107fc7ujmt.b[47][0]++;
            cov_107fc7ujmt.s[134]++;

            param.id = 0;
            cov_107fc7ujmt.s[135]++;
            param.a = '';
            cov_107fc7ujmt.s[136]++;
            param.la = 0;
          } else {
            cov_107fc7ujmt.b[47][1]++;
          }
          cov_107fc7ujmt.s[137]++;
          $scope.process.grapheParams = {
            data: [], data_override: [], labels: [], colors: [], items: [],
            options: {
              tooltips: {
                callbacks: {
                  label: function label(tooltipItem, data) {
                    var myValue = (cov_107fc7ujmt.s[138]++, (cov_107fc7ujmt.b[49][0]++, data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]) || (cov_107fc7ujmt.b[49][1]++, null));
                    cov_107fc7ujmt.s[139]++;
                    if (myValue === null) {
                      cov_107fc7ujmt.b[50][0]++;
                      cov_107fc7ujmt.s[140]++;

                      return null;
                    } else {
                      cov_107fc7ujmt.b[50][1]++;
                    }
                    var labelValue = (cov_107fc7ujmt.s[141]++, $filter('number')(myValue));
                    cov_107fc7ujmt.s[142]++;
                    if ((cov_107fc7ujmt.b[52][0]++, $scope.analyse.FIELD == 1) || (cov_107fc7ujmt.b[52][1]++, $scope.analyse.FIELD == 5)) {
                      cov_107fc7ujmt.b[51][0]++;
                      cov_107fc7ujmt.s[143]++;

                      labelValue += '%';
                    } else {
                      cov_107fc7ujmt.b[51][1]++;
                    }
                    cov_107fc7ujmt.s[144]++;
                    return labelValue;
                  }
                }
              },
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    callback: function callback(value, index, values) {
                      var labelValue = (cov_107fc7ujmt.s[145]++, $filter('number')(value));
                      cov_107fc7ujmt.s[146]++;
                      if ((cov_107fc7ujmt.b[54][0]++, $scope.analyse.FIELD == 1) || (cov_107fc7ujmt.b[54][1]++, $scope.analyse.FIELD == 5)) {
                        cov_107fc7ujmt.b[53][0]++;
                        cov_107fc7ujmt.s[147]++;

                        labelValue += '%';
                      } else {
                        cov_107fc7ujmt.b[53][1]++;
                      }
                      cov_107fc7ujmt.s[148]++;
                      return labelValue;
                    }
                  }
                }]
              },
              legend: {
                display: true,
                position: 'top'
              },
              maintainAspectRatio: false,
              onClick: function onClick(params, element) {
                cov_107fc7ujmt.s[149]++;

                for (var i = $scope.analyse.SERIES.length - 1; i >= 0; i--) {
                  cov_107fc7ujmt.s[150]++;

                  if (angular.isDefined(element[i])) {
                    cov_107fc7ujmt.b[55][0]++;
                    cov_107fc7ujmt.s[151]++;

                    $scope.process.deroule(element[i]._index);
                    cov_107fc7ujmt.s[152]++;
                    return;
                  } else {
                    cov_107fc7ujmt.b[55][1]++;
                  }
                }
              },

              elements: {
                line: {
                  fill: false,
                  cubicInterpolationMode: 'monotone',
                  spanGaps: false,
                  tension: 1
                }
              }
            }
          };
          var complement = (cov_107fc7ujmt.s[153]++, []);
          cov_107fc7ujmt.s[154]++;
          for (var i = 0; i < $scope.analyse.SERIES.length; i++) {
            cov_107fc7ujmt.s[155]++;

            $scope.data.push({});
            cov_107fc7ujmt.s[156]++;
            $scope.process.grapheParams.data.push([]);
            cov_107fc7ujmt.s[157]++;
            $scope.process.grapheParams.data_override.push({
              label: $scope.analyse.SERIES[i].TITLE,
              borderWidth: $scope.analyse.SERIES[i].TYPE == 1 ? (cov_107fc7ujmt.b[56][0]++, 3) : (cov_107fc7ujmt.b[56][1]++, 1),
              type: $scope.analyse.SERIES[i].TYPE == 1 ? (cov_107fc7ujmt.b[57][0]++, 'line') : (cov_107fc7ujmt.b[57][1]++, 'bar')
            });
            cov_107fc7ujmt.s[158]++;
            $scope.process.grapheParams.colors.push('rgb(' + $scope.analyse.SERIES[i].COLOR + ')');
            cov_107fc7ujmt.s[159]++;
            complement.push({ get: 'combine', ida: $scope.analyse.id, i: i });
          }
          cov_107fc7ujmt.s[160]++;
          $scope.isloading = true;
          cov_107fc7ujmt.s[161]++;
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = complement[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var c = _step3.value;
              cov_107fc7ujmt.s[162]++;

              $CRUDService.getAll(PATH, Object.assign(c, param), function (data) {
                cov_107fc7ujmt.f[10]++;
                cov_107fc7ujmt.s[163]++;

                $scope.data[data.index] = data;
                cov_107fc7ujmt.s[164]++;
                $scope.process.__launchDrawing();
              });
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }
        },
        open: function open() {
          cov_107fc7ujmt.s[165]++;

          $scope.data = [];
          cov_107fc7ujmt.s[166]++;
          this.itemCol = $scope.analyse.AXE == 3 ? (cov_107fc7ujmt.b[58][0]++, {
            level: 0,
            CODE_LC: '',
            id: null,
            CODE_LP: -1
          }) : (cov_107fc7ujmt.b[58][1]++, $scope.analyse.AXE == 1 ? (cov_107fc7ujmt.b[59][0]++, {
            level: 0,
            CODE_CLC: '',
            id: null,
            CODE_CLP: -1
          }) : (cov_107fc7ujmt.b[59][1]++, {
            id: '0_0_0'
          }));
          cov_107fc7ujmt.s[167]++;
          this.loadData();
        }
      };
      cov_107fc7ujmt.s[168]++;
      $scope.process.open();
    }
  };
});